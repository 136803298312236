import { Component } from "react";
import { useLocation } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import { Table, Space, notification } from "antd";
import warning from "../../../common/NotificationModal/assets/Exclamation.svg";
import { Image } from "../../../common/NotificationModal/styles";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import DropDown from "../../../common/DropDown";
import { getCountryCodeList } from "../../../../constants/countryCode";
import { getCountryInitial } from "constants/countryCode";
import countryList from "constants/countryList";
import { IS_NUMBER, IS_VALID_EMAIL, SNOWFLAKE } from "../../../../util/common";
import NotificationModal from "../../../common/NotificationModal";
import {
  ContainerWithGrid,
  GridColumn,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import { InputBorderDefault, InputBorderInvalid } from "../constant";
import { JS_COOKIE, REGISTER_THERAPIST_SUBMIT } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { isImageFileSizeBelow5MB, getBase64 } from "util/therapist";
import UploaderOnGuide from "util/uploaderOnGuide";
import ImageUploader from "components/common/ImageUploader";
import TherapistBioTextbox from "components/common/TherapistBioTextbox";
import TimeZoneDropdown from "components/common/TimeZoneDropdown";
import { THERAPIST_PROFILE_PICTURE_FOLDER } from "constants/common";
import { RedAsterisk } from "../styles";
import { Title } from "components/Admin/User/Edit/styles";
import { UploadContainer } from "components/common/VideoModal/style";

interface CreateTherapistProps {
  history: History<LocationState>;
}

interface CreateTherapistStates {
  [key: string]: string | boolean | number | Array<Object> | Array<string>;
  firstName: string;
  lastName: string;
  country: string;
  countryCode: string;
  phone: string;
  email: string;
  password: string;
  message: string;
  timeZone: string;
  isUploaded: boolean;
  originFileObj: any;
  avatarPreview: string;
  encodedFilename: string;
  title: string;
  bio: Array<{ unique_bio_id: string; bio: string; language: string }>;
  isLoading: boolean;
  consultationUrl: string;
  cnConsultationUrl: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = CreateTherapistProps & WithTranslation;
class CreateTherapist extends Component<Props, CreateTherapistStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      country: "",
      countryCode: "",
      phone: "",
      email: "",
      password: "",
      message: "",
      avatarPreview: "",
      encodedFilename: "",
      originFileObj: "",
      isUploaded: false,
      consultationUrl: "",
      cnConsultationUrl: "",
      title: "",
      bio: [],
      isLoading: false,
      timeZone: "",
      showModal: false,
      notificationStatus: false,
    };
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const input = document.getElementById(id);
    if (input) {
      input.style.border = InputBorderDefault;
    }
    this.setState({ [id]: value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
    const countryCode = getCountryInitial(value);
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].key === countryCode?.toLowerCase()) {
        this.setState({ country: countryList[i].key });
        return;
      }
    }
  };

  handleStatus = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  openNotification = (description: string) => {
    notification.info({
      message: <p style={{ color: "White" }}>Error</p>,
      icon: <Image src={warning} style={{ marginBottom: "2px" }} alt="" />,
      description: description,
      placement: "top",
      style: {
        color: "white",
        backgroundColor: "#e8384f",
        height: "90px",
        width: "100%",
      },
    });
  };

  changeInputColor = (id) => {
    const input = document.getElementById(id);
    if (input) {
      input.style.border = InputBorderInvalid;
    }
  };

  validation = () => {
    const {
      email,
      phone,
      countryCode,
      firstName,
      lastName,
      password,
      timeZone,
      bio,
      title,
      avatarPreview,
    } = this.state;
    let count = 0;
    if (firstName === "") {
      count += 1;
      this.changeInputColor("firstName");
      this.openNotification("First name is missing");
    }
    if (lastName === "") {
      count += 1;
      this.changeInputColor("lastName");
      this.openNotification("Last name is missing");
    }
    if (countryCode === "") {
      count += 1;
      this.changeInputColor("countryCode");
      this.openNotification("Country code is missing");
    }
    if (phone === "") {
      count += 1;
      this.changeInputColor("phone");
      this.openNotification("Phone number is missing");
    }
    if (!IS_NUMBER(phone)) {
      count += 1;
      this.setState({ phone: "" });
      this.changeInputColor("phone");
      this.openNotification("Phone number is invalid");
    }
    if (email === "") {
      count += 1;
      this.changeInputColor("email");
      this.openNotification("Email is missing");
    }
    if (!IS_VALID_EMAIL(email)) {
      count += 1;
      this.setState({ email: "" });
      this.changeInputColor("email");
      this.openNotification("Email is invalid");
    }
    if (password === "") {
      count += 1;
      this.changeInputColor("password");
      this.openNotification("Password can not be empty");
    }
    if (timeZone === "") {
      count += 1;
      this.changeInputColor("timeZone");
      this.openNotification("Time zone is missing");
    }
    if (!title) {
      count += 1;
      this.changeInputColor("title");
      this.openNotification("Title is missing");
    }
    if (!avatarPreview) {
      count += 1;
      this.openNotification("Profile picture is missing");
    }
    if (bio.length === 0) {
      count += 1;
      this.openNotification("Bio is missing");
    } else {
      for (let i = 0; i < bio.length; i++) {
        if (bio[i] === undefined) {
          this.openNotification("Bio is missing");
          count += 1;
        } else if (!bio[i].bio) {
          this.openNotification("Bio content is missing");
          count += 1;
        } else if (!bio[i].language) {
          this.openNotification("Bio language is missing");
          count += 1;
        }
      }
    }
    return count === 0;
  };

  onCancelClick = () => {
    this.props.history.push("/admin/therapist");
  };

  handleTimeZoneChange = (value: string) => {
    if (value) {
      this.setState({ timeZone: value });
    }
  };

  beforeUpload = (file: any) => {
    try {
      if (!file) throw new Error("File is unavailable!");
      return isImageFileSizeBelow5MB(file.size);
    } catch (err: any) {
      this.displayErrorMessage(err.message);
      return false;
    }
  };
  handleUploadImageForPreview = async (e: any) => {
    try {
      const { file } = e;
      if (!file) throw new Error("File is unavailable!");
      if (file.status === "removed") {
        this.setState({ avatarPreview: "", isUploaded: false });
        return;
      }
      const originFileObj = file.originFileObj;

      const filePreview = await getBase64(originFileObj);
      this.setState({
        originFileObj: originFileObj,
        avatarPreview: filePreview as string,
      });
    } catch (err: any) {
      this.displayErrorMessage(err.message);
    }
  };

  handleUploadImageToS3 = (therapistUUID: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { originFileObj } = this.state;
      const extension = originFileObj.type === "image/png" ? ".png" : ".jpg";
      const encodedFileName = therapistUUID + extension;
      UploaderOnGuide(
        originFileObj,
        THERAPIST_PROFILE_PICTURE_FOLDER,
        encodedFileName
      ).send((err, data) => {
        if (err) {
          reject(err);
          return;
        }
        this.setState(
          {
            isUploaded: true,
            encodedFilename: encodedFileName,
          },
          () => resolve()
        );
      });
    });
  };

  handleBio = (bioList: any) => {
    if (bioList) this.setState({ bio: bioList });
  };

  filterBioData = (language: string) => {
    const { bio } = this.state;

    if (!bio || bio.length === 0) return [];

    return bio.filter((item) => {
      if (!item) return false;
      else if (!item.language) {
        if (language === "EN") return true;
        return false;
      }
      return item.language === language;
    });
  };

  handleDeleteBio = (bioId) => {
    // This does nothing, but we put it here to streamline with the Edit Component
  };
  onSaveClick = async () => {
    try {
      if (this.validation() === false) throw new Error("Err by validation");
      this.setState({ isLoading: true });
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);

      const uuid = SNOWFLAKE.gen();
      await this.handleUploadImageToS3(uuid);
      if (this.state.isUploaded === false) {
        this.setState({ isLoading: false });
        throw new Error("Upload profile picture failed");
      }
      const {
        email,
        password,
        country,
        phone,
        countryCode,
        firstName,
        lastName,
        encodedFilename,
        consultationUrl,
        cnConsultationUrl,
        bio,
        title,
        timeZone,
      } = this.state;
      const newUser = {
        uuid: uuid,
        firstName,
        lastName,
        email,
        password,
        country,
        countryCode,
        phone,
        consultationUrl,
        cnConsultationUrl,
        avatarFilename: encodedFilename,
        title,
        timeZone,
      };
      const res = await REGISTER_THERAPIST_SUBMIT(newUser, bio);
      const { status, message } = res;
      if (status === "ERROR") {
        this.setState({ isLoading: false });
        throw new Error(message);
      }

      this.setState({ isLoading: false });
      this.props.history.push("/admin/therapist");
    } catch (error: any) {
      if (error.message !== "Err by validation")
        this.displayErrorMessage(error.message);
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      message: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  render = () => {
    const { t } = this.props;

    const {
      showModal,
      message,
      notificationStatus,
      firstName,
      lastName,
      country,
      countryCode,
      phone,
      email,
      password,
      isUploaded,
      avatarPreview,
      encodedFilename,
      consultationUrl,
      cnConsultationUrl,
      timeZone,
      title,
      bio,
      isLoading,
    } = this.state;
    const dataSourceEN = this.filterBioData("EN");
    const dataSourceZH_CN = this.filterBioData("ZH-CN");
    const dataSourceZH_TW = this.filterBioData("ZH-TW");
    const columnEN = [
      {
        title: "English",
        dataIndex: "bio",
        key: "bio",
        width: "1500px",
      },
    ];

    const columnZH_CN = [
      {
        title: "Simplified Chinese",
        dataIndex: "bio",
        key: "bio",
        width: "1500px",
      },
    ];

    const columnZH_TW = [
      {
        title: "Traditional Chinese",
        dataIndex: "bio",
        key: "bio",
        width: "1500px",
      },
    ];
    const image = {
      isUploaded,
      avatarPreview,
      avatarFilename: encodedFilename,
    };

    return (
      <div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              fontSize: "40px",
            }}
          >
            Creating profile...
          </div>
        ) : (
          <div>
            <NotificationModal
              show={showModal}
              message={message}
              status={notificationStatus}
            />
            <ContainerWithGrid>
              <GridColumn>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.firstName")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="firstName"
                    type="text"
                    value={firstName}
                  />
                </ContentContainer>

                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.lastName")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="lastName"
                    type="text"
                    value={lastName}
                  />
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.therapistTitle")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="title"
                    type="text"
                    value={title}
                  />
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.timeZone")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <TimeZoneDropdown
                    userId={"0"}
                    timeZone={timeZone}
                    userUuid={"UUID"}
                    showNotification={this.handleStatus}
                    handleTimeZoneChange={this.handleTimeZoneChange}
                  />
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.countryCode")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <DropDown
                    componentName="admin - therapist"
                    type="Country code"
                    size="medium-adminCreateTherapistPanel"
                    defaultSelection={countryCode}
                    selectedText={countryCode}
                    options={getCountryCodeList(t)}
                    onOptionChange={this.handleCountryCodeChange}
                  />
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.phone")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="phone"
                    type="tel"
                    value={phone}
                  />
                </ContentContainer>

                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.email")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="email"
                    type="email"
                    value={email}
                  />
                </ContentContainer>

                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.password")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="password"
                    type="password"
                    value={password}
                  />
                </ContentContainer>
              </GridColumn>
              <GridColumn>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.consultationUrl")}
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="consultationUrl"
                    type="text"
                    value={consultationUrl}
                  />
                </ContentContainer>

                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.backupConsultationUrl")}
                  </ContentSubtitle>
                  <Input
                    onChange={this.handleInputChange}
                    id="cnConsultationUrl"
                    type="text"
                    value={cnConsultationUrl}
                  />
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.therapist.profilePicture")}
                    <RedAsterisk>*</RedAsterisk>
                  </ContentSubtitle>
                  <ImageUploader
                    image={image}
                    beforeUpload={this.beforeUpload}
                    handleUploadImageForPreview={
                      this.handleUploadImageForPreview
                    }
                  />
                </ContentContainer>
              </GridColumn>
            </ContainerWithGrid>
            <div style={{ width: "90%", marginLeft: "5%" }}>
              <Title>
                {t("admin.therapist.bio")}
                <RedAsterisk>*</RedAsterisk>
              </Title>
              <UploadContainer>
                <ContentSubtitle
                  style={{ fontSize: "16px", fontStyle: "italic" }}
                >
                  Can add up to 3 description fields per language
                </ContentSubtitle>
                <TherapistBioTextbox
                  currParentComponent="create"
                  bio={bio}
                  handleBio={this.handleBio}
                  handleDeleteBio={this.handleDeleteBio}
                />
                <div style={{ display: "flex" }}>
                  {dataSourceEN.length > 0 && (
                    <Table
                      pagination={false}
                      columns={columnEN}
                      dataSource={dataSourceEN}
                    ></Table>
                  )}
                  {dataSourceZH_CN.length > 0 && (
                    <Table
                      pagination={false}
                      columns={columnZH_CN}
                      dataSource={dataSourceZH_CN}
                    ></Table>
                  )}
                  {dataSourceZH_TW.length > 0 && (
                    <Table
                      pagination={false}
                      columns={columnZH_TW}
                      dataSource={dataSourceZH_TW}
                    ></Table>
                  )}
                </div>
              </UploadContainer>
            </div>
            <ContentContainer>
              <CancelButton onClick={this.onCancelClick}>
                {t("admin.common.cancel")}
              </CancelButton>
              <SaveButton onClick={this.onSaveClick}>
                {t("admin.common.save")}
              </SaveButton>
            </ContentContainer>
          </div>
        )}
      </div>
    );
  };
}

export default withTranslation()(CreateTherapist);
