import { LIMIT } from "../constants/admin";
export const getPageStartAndEnd = (offset: number, length: number) => {
  if (length === 0) {
    return {
      pageStart: 0,
      pageEnd: 0,
    };
  }
  const pageStart = offset * LIMIT + 1;
  const pageEnd =
    length === LIMIT ? (offset + 1) * LIMIT : pageStart + length - 1;
  return {
    pageStart: pageStart,
    pageEnd: pageEnd,
  };
};

export const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;

  return () => {
    const later = () => {
      clearTimeout(timeout);
      func();
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
