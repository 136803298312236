import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { History, LocationState } from "history";
import { startReset } from "../../../User/Evaluation/Core/utils/actions";
import { AppActions } from "types/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { startLoginFail } from "../../../auth/actions";
import {
  NavbarLinkContainer,
  AdminTabContainer,
  AdminTabTitle,
  AdminTabColumn,
} from "./styles";
import { ADMIN_COOKIE_NAME, ADMIN_COOKIE_ROLE } from "constants/admin";
import { JS_COOKIE } from "util/auth";
import { createStructuredSelector } from "reselect";
import { AppState } from "storeConfig";
import { makeSelectRole } from "components/auth/selectors";
import { ROLE } from "constants/common";

interface AdminBarProps {
  history: History<LocationState>;
}

interface AdminBarState {}

type Props = AdminBarProps &
  WithTranslation &
  LinkDispatchProps &
  LinkStateProps;

class AdminBar extends Component<Props, AdminBarState> {
  onLogoutClick = () => {
    this.props.onLoginFail();
    this.props.onReset();
    JS_COOKIE.remove(ADMIN_COOKIE_NAME);
    JS_COOKIE.remove(ADMIN_COOKIE_ROLE);
    this.props.history.push("/adminLogin");
  };

  handleNextComponent = (componentName: string) => {
    this.props.history.push("/admin/" + componentName);
  };

  render = () => {
    const role = JS_COOKIE.get(ADMIN_COOKIE_ROLE);
    const { t } = this.props;
    const { pathname } = window.location;

    return (
      <NavbarLinkContainer>
        <AdminTabContainer>
          {role === ROLE.admin && (
            <React.Fragment>
              <AdminTabTitle>PenguinSmart Admin Panel</AdminTabTitle>
              <AdminTabColumn
                selected={pathname.includes("user")}
                onClick={() => {
                  this.handleNextComponent("user");
                }}
              >
                User
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("therapist")}
                onClick={() => {
                  this.handleNextComponent("therapist");
                }}
              >
                Therapist
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("payment")}
                onClick={() => {
                  this.handleNextComponent("payment");
                }}
              >
                Payment
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("result")}
                onClick={() => {
                  this.handleNextComponent("result");
                }}
              >
                Result
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("product")}
                onClick={() => {
                  this.handleNextComponent("product");
                }}
              >
                Product
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("coupon")}
                onClick={() => {
                  this.handleNextComponent("coupon");
                }}
              >
                Coupon
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("feedback")}
                onClick={() => {
                  this.handleNextComponent("feedback");
                }}
              >
                Feedback
              </AdminTabColumn>
              <AdminTabColumn
                selected={pathname.includes("organization")}
                onClick={() => {
                  this.handleNextComponent("organization");
                }}
              >
                Organization
              </AdminTabColumn>
            </React.Fragment>
          )}
          {role === ROLE.translator && (
            <AdminTabColumn
              selected={pathname.includes("i18n")}
              onClick={() => {
                this.handleNextComponent("i18n");
              }}
            >
              I18n Editor
            </AdminTabColumn>
          )}
          <AdminTabColumn selected={true} onClick={this.onLogoutClick}>
            {t("general.navbar.logout")}
          </AdminTabColumn>
        </AdminTabContainer>
      </NavbarLinkContainer>
    );
  };
}

interface LinkDispatchProps {
  onLoginFail: () => void;
  onReset: () => void;
}

interface LinkStateProps {
  role: string;
}
const mapStateToProps = createStructuredSelector<AppState, LinkStateProps>({
  role: makeSelectRole(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminBar))
);
