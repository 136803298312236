import { Component } from "react";
import {
  withTranslation,
  WithTranslation,
  useTranslation,
} from "react-i18next";
import {
  A4Container,
  SectionTitle,
  SmallSectionTitle,
  SmallSectionTitleActivities,
  SectionContainer,
  SectionContainerActivities,
  PageNumber,
  WaterMark,
  ContentText,
  ContentTextActivities,
  ActivityTitle,
} from "../styles";
import { activitiesType, activityType, initialActivity } from "../../data";
import {
  ACTIVITY_INDEX,
  INDEX_TO_TYPE,
} from "../../../../../../constants/activities";
import { getWatermark } from "../ImageLoader";
import { objectToArray } from "../../../../../../util/common";

interface ActivityProps {
  language: string;
  activities: activitiesType;
}

interface ActivityState {}

type Props = ActivityProps & WithTranslation;

const Skills = ({ skills }): JSX.Element => {
  const { t } = useTranslation();
  const { description, children, parent } = skills;
  return (
    <SectionContainerActivities>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.skills")}
      </SmallSectionTitleActivities>
      <ContentTextActivities>{description}</ContentTextActivities>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.children")}
      </SmallSectionTitleActivities>
      {children.map((line: string) => {
        return <ContentTextActivities>{line}</ContentTextActivities>;
      })}
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.parent")}
      </SmallSectionTitleActivities>
      {parent.map((line: string) => {
        return <ContentTextActivities>{line}</ContentTextActivities>;
      })}
    </SectionContainerActivities>
  );
};

const ScenarioOrSetup = ({ scenarioOrSetup }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.scenarioOrSetup")}
      </SmallSectionTitleActivities>
      <ContentTextActivities>{scenarioOrSetup}</ContentTextActivities>
    </>
  );
};

const Frequency = ({ frequency }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.frequency")}
      </SmallSectionTitleActivities>
      <ContentTextActivities>{frequency}</ContentTextActivities>
    </>
  );
};

const Steps = ({ steps }): JSX.Element => {
  const { t } = useTranslation();
  if (steps.length === 0) {
    return <div />;
  }
  return (
    <>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.steps")}
      </SmallSectionTitleActivities>
      {steps.map((reaction: string) => (
        <ContentTextActivities>{reaction}</ContentTextActivities>
      ))}
    </>
  );
};

const ParentReaction = ({ parentReactions }): JSX.Element => {
  const { t } = useTranslation();
  if (parentReactions.length === 0) {
    return <div />;
  }
  return (
    <>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.parentReaction")}
      </SmallSectionTitleActivities>
      {parentReactions.map((reaction: string) => (
        <ContentTextActivities>{reaction}</ContentTextActivities>
      ))}
    </>
  );
};

const Notes = ({ notes }): JSX.Element => {
  const { t } = useTranslation();
  if (notes.length === 0) {
    return <div />;
  }
  return (
    <>
      <SmallSectionTitleActivities>
        {t("user.survey.corePro.result.activity.subtitle.notes")}
      </SmallSectionTitleActivities>
      {notes.map((note: string) => (
        <ContentTextActivities>{note}</ContentTextActivities>
      ))}
    </>
  );
};

const ParentAndNotes = ({ parentReactions, notes }) => {
  if (notes.length === 0 && parentReactions.length === 0) {
    return <div />;
  }
  return (
    <SectionContainerActivities>
      <ParentReaction parentReactions={parentReactions} />
      <Notes notes={notes} />
    </SectionContainerActivities>
  );
};

class Activity extends Component<Props, ActivityState> {
  getActivityData = (
    activities: activitiesType,
    index: number
  ): activityType => {
    try {
      const { t } = this.props;
      const typeIndex = activities[`activityType${index}`];
      const activityIndex = activities[`activity${index}`];
      const typeName = INDEX_TO_TYPE[typeIndex];
      const activityTranslation = t(
        `recommendedActivities.${typeName}.${activityIndex}`,
        { returnObjects: true }
      );

      const data: activityType = {
        name: activityTranslation["name"],
        age: activityTranslation["age"],
        skills: {
          description: activityTranslation["skills"]["description"],
          children: objectToArray(activityTranslation["skills"]["children"]),
          parent: objectToArray(activityTranslation["skills"]["parent"]),
        },
        scenarioOrSetup: activityTranslation["scenarioOrSetup"],
        frequency: activityTranslation["frequency"],
        steps: objectToArray(activityTranslation["steps"]),
        parentReactions: objectToArray(activityTranslation["parentReaction"]),
        notes: objectToArray(activityTranslation["notes"]),
      };

      return data;
    } catch (error) {
      return initialActivity;
    }
  };

  render = () => {
    const { t } = this.props;
    const { activities } = this.props;
    const recommendActivities: Array<activityType> = Array.from(
      Array(ACTIVITY_INDEX.count).keys()
    ).map((i: number) => this.getActivityData(activities, i));
    return recommendActivities.map((activity: activityType, index) => {
      return (
        <A4Container>
          {index === 0 && (
            <SectionTitle>
              {t("user.survey.corePro.result.activity.title")}
            </SectionTitle>
          )}
          <ActivityTitle>{activity.name}</ActivityTitle>
          <Skills skills={activity.skills} />
          <SectionContainerActivities>
            <ScenarioOrSetup scenarioOrSetup={activity.scenarioOrSetup} />
            <Frequency frequency={activity.frequency} />
            <Steps steps={activity.steps} />
          </SectionContainerActivities>
          <ParentAndNotes
            parentReactions={activity.parentReactions}
            notes={activity.notes}
          />
          <PageNumber>{9 + index}</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
      );
    });
  };
}
export default withTranslation()(Activity);
