import styled from "styled-components";

export const NavbarLinkContainer = styled.div`
  text-align: center;
  @media only screen and (max-width: 575px) {
    justify-content: space-evenly;
    display: flex;
    padding: 10px 0;
  }
  @media only screen and (min-width: 576px) {
    justify-content: space-evenly;
    display: flex;
    padding: 10px 0;
  }
  @media only screen and (min-width: 768px) {
    display: grid;
  }
  @media only screen and (min-width: 992px) {
    display: grid;
  }
  @media only screen and (min-width: 1200px) {
    display: grid;
  }
`;

export const AdminTabContainer = styled.div`
  position: fixed;
  height: inherit;
  width: 180px;
  background: #c4c4c4;
  font-size: 16px;
`;

export const AdminTabTitle = styled.div`
  margin: 24px 32px 80px 32px;
  font-weight: bold;
`;

export const AdminTabColumn = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? "#A8BF96" : "#DADADA")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#000000")};
  padding-left: 32px;
  height: 55px;
  line-height: 55px;
  box-shadow: inset 0px 0px 1px #000000;
  cursor: pointer;
`;
