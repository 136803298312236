import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { AppState } from "storeConfig";
import { AppActions } from "types/actions";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ROLE } from "constants/common";
import { makeIsUserLoggedIn, makeSelectRole } from "components/auth/selectors";
import {
  startLoginSuccess,
  startUpdateUserToken,
} from "components/auth/actions";
import { LeftNavBar, BarMenuContainer, WordCompanyLogo } from "./styles";
import AdminBar from "./AdminBar";
import TopBar from "./TopBar";
import {
  // CHECK_IF_DISPLAY_LEFT_NAVBAR,
  DESKTOP_LOGO,
  GET_LANGUAGE,
} from "util/common";
import { JS_COOKIE } from "../../../util/auth";
import { ADMIN_COOKIE_ROLE } from "../../../constants/admin";

interface NavMenuProps {}

type Props = NavMenuProps & LinkDispatchProps & LinkStateProp;

function NavMenu(props: Props) {
  const role = useSelector(makeSelectRole());
  const history = useHistory();
  const language = GET_LANGUAGE();
  const { loggedIn } = props;
  const DesktopLogo = DESKTOP_LOGO(language);

  const haveRole = loggedIn && role !== "";
  const isUser = haveRole && role === ROLE.user;
  const isTherapist = haveRole && role === ROLE.therapist;
  const adminRole = JS_COOKIE.get(ADMIN_COOKIE_ROLE);
  const isAdmin =
    haveRole && (adminRole === ROLE.admin || adminRole === ROLE.translator);
  // const isTmpUser = haveRole && role === ROLE.tmpUser;
  // const displayLeft = CHECK_IF_DISPLAY_LEFT_NAVBAR(pathname);
  return (
    <div>
      {isAdmin && (
        <LeftNavBar>
          <BarMenuContainer>
            <WordCompanyLogo
              src={DesktopLogo}
              onClick={() => {
                if (isAdmin) {
                  history.push("/adminLogin");
                } else {
                  history.push("/profile");
                }
              }}
            />
            <AdminBar />
            {/* {loggedIn && role === ROLE.therapist && <TherapistBar />} */}
            {/* {!loggedIn && <GuestBar history={history} />} */}
          </BarMenuContainer>
        </LeftNavBar>
      )}
      {(isUser || isTherapist) && <TopBar role={role} />}
    </div>
  );
}

interface LinkStateProp {
  loggedIn: boolean;
  role: string;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  loggedIn: makeIsUserLoggedIn(),
  role: makeSelectRole(),
});

interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onUpdateUserToken: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
