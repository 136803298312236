import styled from "styled-components";

export const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const CompletedContainer = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-around;
  border-radius: 4px;
  border: solid 1px #a8bf96;
`;

export const CompletedButton = styled.div<{ isChecked: boolean }>`
  flex: 1 1 150px;
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 20px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${(props) => (props.isChecked ? "#a8bf96" : "#dadada")};
  color: ${(props) => (props.isChecked ? "#ffffff" : "#000000")};
`;

export const ChildInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  border: solid 1px #a8bf96;
  border-radius: 4px;
`;

export const ChildInfoTitle = styled.div`
  text-align: center;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #dadada;
  font-size: 20px;
  height: 36px;
  line-height: 30px;
`;

export const BasicInfoContainer = styled.div`
  flex: 1 1 400px;
  margin: 5px;
  border: solid 1px #a8bf96;
  border-radius: 4px;
`;

export const BasicInfoTitle = styled.div`
  width: 100%;
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #dadada;
  color: #000000;
  font-size: 20px;
  height: 36px;
  line-height: 30px;
`;

export const BasicInfoContent = styled.div``;

export const ChildName = styled.input`
  width: 150px;
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
`;

export const ChildAge = styled.input`
  width: 75px;
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
`;

export const MoreInfoContainer = styled.div`
  flex: 1 1 400px;
  margin: 5px;
  border: solid 1px #a8bf96;
  border-radius: 4px;
`;

export const MoreInfoTitle = styled.div`
  width: 100%;
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #dadada;
  color: #000000;
  font-size: 20px;
  height: 36px;
  line-height: 30px;
`;

export const FullResult = styled.div`
  width: 100%;
  text-align: center;
  background-color: #a8bf96;
  color: #ffffff;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;

export const TherapistContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  border: solid 1px #a8bf96;
  border-radius: 4px;
`;

export const TherapistTitle = styled.div`
  width: 100%;
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #dadada;
  font-size: 20px;
  height: 36px;
  line-height: 30px;
`;

export const VideoContainer = styled.div`
  border: solid 1px #a8bf96;
  border-radius: 4px;
  flex: 1 1 200px;
  margin: 5px;
`;

export const VideoTitle = styled.div`
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #dadada;
  color: #000000;
  font-size: 20px;
  height: 36px;
  line-height: 30px;
  width: calc(100%+2px);
`;

export const Video = styled.div`
  width: 100%;
  text-align: center;
  background-color: #a8bf96;
  color: #ffffff;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Assign = styled.div`
  width: 50%;
  text-align: center;
  background-color: #a8bf96;
  // background-color: #f9fff6;
  margin-left: 5px;
  border: solid 1px #a8bf96;
  color: #ffffff;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;

export const ReportContainer = styled.div`
  border: solid 1px #a8bf96;
  border-radius: 4px;
  flex: 1 1 200px;
  margin: 5px;
`;

export const ReportTitle = styled.div`
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #dadada;
  color: #000000;
  font-size: 20px;
  height: 36px;
  line-height: 30px;
`;

export const Report = styled.div`
  width: 100%;
  text-align: center;
  background-color: #a8bf96;
  color: #ffffff;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Reject = styled.div`
  width: 50%;
  margin-right: 5px;
  text-align: center;
  // background-color: #f9fff6;
  background-color: #a8bf96;
  border: solid 1px #a8bf96;
  color: #ffffff;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Edit = styled.div`
  width: 50%;
  margin-right: 5px;
  text-align: center;
  background-color: #f9fff6;
  border: solid 1px #a8bf96;
  color: #000000;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Submit = styled.div`
  width: 50%;
  margin-left: 5px;
  text-align: center;
  background-color: #f9fff6;
  border: solid 1px #a8bf96;
  color: #000000;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
`;
