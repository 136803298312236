import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../common/NotificationModal";
import ReferralLinkModal from "../../common/ReferralLinkModal";
import Pagination from "../../common/Pagination";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { getPageStartAndEnd } from "../../../util/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import {
  Container,
  Table,
  Header,
  Row,
  ToolContainer,
  Page,
  Button,
  TD,
} from "../styles";
import { JS_COOKIE } from "util/auth";

interface priceType {
  cny: number;
  usd: number;
  twd: number;
}

interface productType {
  id: string;
  name: string;
  price: priceType;
  createdat: string;
}

interface ProductProps {
  history: History<LocationState>;
}

interface ProductStates {
  product: Array<productType>;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  rowCount: number;
  pageCount: number;
  offset: number;
  selectedItem: number;
  showReferralLinkModal: boolean;
}

type Props = ProductProps & WithTranslation;
class Product extends Component<Props, ProductStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      product: [],
      message: "",
      showModal: false,
      notificationStatus: false,
      rowCount: 0,
      pageCount: 0,
      offset: 0,
      selectedItem: -1,
      showReferralLinkModal: false,
    };
  }

  componentDidMount = () => {
    this.getProductData();
  };

  componentDidUpdate = (prevProps: ProductProps, prevState: ProductStates) => {
    if (prevState.offset === this.state.offset) {
      return;
    }
    this.getProductData();
  };

  getProductData = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { offset } = this.state;
    axios
      .get(api_address + "api/admin/product", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: offset * LIMIT,
          limit: LIMIT,
        },
      })
      .then((res) => {
        const { product, rowCount } = res.data;
        const pageCount = Math.ceil(rowCount / LIMIT);
        this.setState({
          product: product,
          rowCount: rowCount,
          pageCount: pageCount,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  handlePageChange = (offset: number) => {
    this.setState({ offset: offset });
  };

  onRowClick = (index: number) => {
    const { selectedItem } = this.state;
    if (selectedItem === index) {
      this.setState({ selectedItem: -1 });
    } else {
      this.setState({ selectedItem: index });
    }
  };

  onEditClick = () => {
    const { selectedItem, product } = this.state;
    const ID = product[selectedItem].id;
    this.props.history.push("/admin/product/edit/" + ID);
  };

  onCreateClick = () => {
    const { selectedItem, product } = this.state;
    const ID = product[selectedItem].id;
    this.props.history.push("/admin/coupon/create/" + ID);
  };

  handleOpenReferralLinkModal = () => {
    this.setState({ showReferralLinkModal: true });
  };

  handleCloseReferralLinkModal = () => {
    this.setState({ showReferralLinkModal: false });
  };

  tableData = () => {
    const { product, selectedItem } = this.state;
    const { t } = this.props;
    return product.map((product: productType, index: number) => {
      const { id, name, price, createdat } = product;
      const { cny, twd, usd } = price;
      const isSelected = selectedItem === index;
      return (
        <Row
          key={index}
          isSelected={isSelected}
          onClick={() => {
            this.onRowClick(index);
          }}
        >
          <td>{id}</td>
          <td>{name}</td>
          <td>{t(`admin.product.${name}`)}</td>
          <td>{cny}</td>
          <td>{twd}</td>
          <td>{usd}</td>
          <TD>{GET_DATE_TIME_STRING_FROM_TIMESTAMP(createdat)}</TD>
        </Row>
      );
    });
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      rowCount,
      pageCount,
      offset,
      selectedItem,
      product,
    } = this.state;

    const { pageStart, pageEnd } = getPageStartAndEnd(offset, product.length);
    const showCreateButton = selectedItem >= 0;

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <ReferralLinkModal {...this.state} {...this} />
        <ToolContainer>
          <Button onClick={this.handleOpenReferralLinkModal}>
            {t("admin.coupon.link")}
          </Button>
          {showCreateButton && (
            <>
              <Button onClick={this.onCreateClick}>Create Coupon</Button>
              <Button onClick={this.onEditClick}>Edit</Button>
            </>
          )}
        </ToolContainer>
        <Table id="product">
          <tbody>
            <Header>
              <th>{t("admin.product.id")}</th>
              <th>{t("admin.product.name")}</th>
              <th>{t("admin.product.externalName")}</th>
              <th>{t("admin.product.cny")}</th>
              <th>{t("admin.product.twd")}</th>
              <th>{t("admin.product.usd")}</th>
              <th>{t("admin.product.createdAt")}</th>
            </Header>
            {this.tableData()}
          </tbody>
        </Table>
        <ToolContainer>
          <Pagination
            selectedItem={offset}
            pageCount={pageCount}
            handlePageChange={this.handlePageChange}
          />
          <Page>{`${pageStart} - ${pageEnd} of ${rowCount}`}</Page>
        </ToolContainer>
      </Container>
    );
  };
}

export default withTranslation()(Product);
