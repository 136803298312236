import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Container,
  ModalContent,
  ButtonContainer,
  CloseButton,
  Content,
  VideoContainer,
  Video,
  UploadBox,
  UploadInput,
  UploadButton,
  UploadButtonText,
  ProgressBarContainer,
  ProgressBarLine,
  BarLine,
  Comment,
} from "./style";
import addFile from "../../User/Evaluation/CorePro/UploadVideoSection/images/AddFile-2x.png";
import fileSuccess from "../../User/Evaluation/CorePro/UploadVideoSection/images/FileSuccess-2x.png";
import getSignedUrl from "../../../util/Downloader";
import Notification from "../NotificationModal";

interface videoType {
  transactionId: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1aws: boolean;
  completed1: number;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2aws: boolean;
  completed2: number;
}

interface VideoModalProps {
  show: boolean;
  video: videoType;
  handleSave: () => void;
  handleClose: () => void;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface States {
  showNotification: boolean;
  message: string;
  notificationStatus: boolean;
}

type Props = VideoModalProps & WithTranslation;

class VideoModal extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showNotification: false,
      message: "",
      notificationStatus: false,
    };
  }

  componentDidUpdate = (prevProps: VideoModalProps) => {
    const { video1aws, video2aws } = this.props.video;
    if (
      ((!prevProps.video.video1aws && video1aws) ||
        (!prevProps.video.video2aws && video2aws)) &&
      prevProps.video.video1 != null
    ) {
      this.showNotification(
        "video upload successful, click save to update the database",
        true
      );
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showNotification: true,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  render() {
    const { show, t, handleClose, video, handleUploadFile, handleSave } =
      this.props;
    const { showNotification, message, notificationStatus } = this.state;
    const {
      video1,
      video1comment,
      encodedVideoName1,
      video1aws,
      video2,
      video2comment,
      encodedVideoName2,
      video2aws,
      completed1,
      completed2,
      transactionId,
    } = video;
    return (
      <Container id="ModalContainer" show={show}>
        <ModalContent id="ModalContent">
          <Notification
            show={showNotification}
            message={message}
            status={notificationStatus}
          />
          <Content>
            {video1aws ? (
              <VideoContainer>
                <video
                  src={getSignedUrl(transactionId, encodedVideoName1)}
                  width={"100%"}
                  height={"100%"}
                  controls
                />
              </VideoContainer>
            ) : (
              <VideoContainer>
                <Video
                  onClick={() => {
                    window.open(video1, "_blank");
                  }}
                >
                  Click here to verify the video
                </Video>
                {video1comment && (
                  <Comment>{`Comments: ${video1comment}`}</Comment>
                )}
                <UploadBox>
                  <UploadInput
                    type="file"
                    id={`Upload_1`}
                    accept="audio/x-m4a,audio/*,video/*"
                    onChange={handleUploadFile}
                  />
                  <UploadButton
                    src={completed1 === 100 ? fileSuccess : addFile}
                    htmlFor={`Upload_1`}
                  >
                    <UploadButtonText>
                      {video1
                        ? video1
                        : t(
                            "user.survey.corePro.video.videoRecording.uploadText"
                          )}
                    </UploadButtonText>
                  </UploadButton>
                </UploadBox>
                <ProgressBarContainer>
                  <ProgressBarLine />
                  <BarLine barWidth={`${completed1}%`} />
                </ProgressBarContainer>
              </VideoContainer>
            )}
            {video2aws ? (
              <VideoContainer>
                <video
                  src={getSignedUrl(transactionId, encodedVideoName2)}
                  width={"100%"}
                  height={"100%"}
                  controls
                />
              </VideoContainer>
            ) : (
              <VideoContainer>
                <Video
                  onClick={() => {
                    window.open(video2, "_blank");
                  }}
                >
                  Click here to verify the video
                </Video>
                {video2comment && (
                  <Comment>{`Comments: ${video2comment}`}</Comment>
                )}
                <UploadBox>
                  <UploadInput
                    type="file"
                    id={`Upload_2`}
                    accept="audio/x-m4a,audio/*,video/*"
                    onChange={handleUploadFile}
                  />
                  <UploadButton
                    src={completed2 === 100 ? fileSuccess : addFile}
                    htmlFor={`Upload_2`}
                  >
                    <UploadButtonText>
                      {video2
                        ? video2
                        : t(
                            "user.survey.corePro.video.videoRecording.uploadText"
                          )}
                    </UploadButtonText>
                  </UploadButton>
                </UploadBox>
                <ProgressBarContainer>
                  <ProgressBarLine />
                  <BarLine barWidth={`${completed2}%`} />
                </ProgressBarContainer>
              </VideoContainer>
            )}
          </Content>
          <ButtonContainer>
            <CloseButton onClick={handleClose}>Close</CloseButton>
            <CloseButton onClick={handleSave}>Save</CloseButton>
          </ButtonContainer>
        </ModalContent>
      </Container>
    );
  }
}

export default withTranslation()(VideoModal);
