/* eslint-disable no-control-regex */
import i18n from "i18next";
import flakeID from "flakeid";
import Logo_EN from "../assets/Logo_EN.png";
import Logo_CN from "../assets/Logo_CN.png";
import Logo_TW from "../assets/Logo_TW.png";
import moment from "moment";
import axios from "axios";
import api_address from "constants/config";
import { JS_COOKIE } from "./auth";

export const GET_TITLE_BAR = (
  t: Function,
  component: string,
  withCompanyName: boolean
) => {
  return withCompanyName
    ? t(`general.titleBar.${component}`) + " | " + t("general.companyName")
    : t(`general.titleBar.${component}`);
};

export const SNOWFLAKE = new flakeID({
  mid: 42,
  offset: (2020 - 1970) * 31536000 * 1000,
});

export const IS_VALID_URL = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const IS_VALID_EMAIL = (str: string) => {
  const pattern = new RegExp(/\b[a-zA-Z0-9._]+@[a-z0-9.]+\b/);
  const result = pattern.exec(str);
  const valid = result && str === result[0];
  return valid;
};

export const IS_NUMBER = (n) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

interface YearsAndMonths {
  years: number;
  months: number;
}

export const CALCULATE_YEAR_MONTH = (
  year: string,
  month: string,
  day: string
): YearsAndMonths => {
  const today = moment();
  const birthday = moment(year + "/" + month + "/" + day);
  const months = today.diff(birthday, "months");

  return {
    years: Math.floor(months / 12),
    months: months % 12,
  };
};

export const DAYS_AND_HOURS_BETWEEN_TWO_DATE = (
  startDate: string,
  endDate: string
) => {
  const secondsPerDay = 86400;
  const secondsPerHour = 3600;
  const start = new Date(startDate);
  const end = new Date(endDate);
  const secondsFromNow = Math.floor((end.getTime() - start.getTime()) / 1000);
  const daysFromNow = Math.floor(secondsFromNow / secondsPerDay);
  const hoursFromNow = Math.floor(
    (secondsFromNow - daysFromNow * secondsPerDay) / secondsPerHour
  );
  return { daysFromNow, hoursFromNow };
};

export const GET_DATE_TIME_STRING_FROM_TIMESTAMP = (
  timestamp: string
): string => {
  if (!timestamp) {
    return "";
  }
  const dateTime = new Date(timestamp);
  return (
    dateTime.getFullYear() +
    "/" +
    (dateTime.getMonth() + 1) +
    "/" +
    dateTime.getDate() +
    "\n" +
    dateTime.getHours() +
    ":" +
    dateTime.getMinutes() +
    ":" +
    dateTime.getSeconds()
  );
};

export const FORMAT_NAME_BY_COUNTRY = (firstName, lastName, country) => {
  if (country === "cn" || country === "tw" || country === "hk") {
    return `${lastName}${firstName}`;
  }
  return `${firstName} ${lastName}`;
};

export const CHECK_IF_DISPLAY_BOTTOM_NAVBAR = (pathname: string) => {
  if (
    pathname.includes("forgot") ||
    pathname.includes("reset") ||
    pathname.includes("privacy-policy") ||
    pathname.includes("terms-conditions") ||
    pathname.includes("expert-team") ||
    pathname.includes("upload-instructions") ||
    pathname.includes("account-setting") ||
    pathname.includes("evaluation-history") ||
    pathname.includes("order-history") ||
    pathname.includes("FAQ") ||
    pathname.includes("purchases") ||
    pathname.includes("questionnaire") ||
    pathname.includes("core-report") ||
    pathname.includes("video") ||
    pathname.includes("waiting-section") ||
    pathname.includes("core-pro-report") ||
    pathname.includes("Organization")
  ) {
    return false;
  }
  return true;
};

export const CHECK_IF_DISPLAY_LEFT_NAVBAR = (pathname: string) => {
  if (
    pathname.includes("forgot") ||
    pathname.includes("reset") ||
    pathname.includes("privacy-policy") ||
    pathname.includes("terms-conditions") ||
    pathname.includes("expert-team") ||
    pathname.includes("upload-instructions") ||
    pathname.includes("purchases") ||
    pathname.includes("questionnaire") ||
    pathname.includes("core-report") ||
    pathname.includes("video") ||
    pathname.includes("waiting-section") ||
    pathname.includes("core-pro-report") ||
    pathname.includes("Organization")
  ) {
    return false;
  }
  return true;
};

export const DESKTOP_LOGO = (language: string) => {
  if (language === "en") {
    return Logo_EN;
  } else if (language === "zh-CN") {
    return Logo_CN;
  } else if (language === "zh-TW") {
    return Logo_TW;
  } else {
    return Logo_CN;
  }
};

const existLanguage = ["en", "zh-CN", "zh-TW"];

export const NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE = (
  locale: string
): string => {
  const localeMap: Record<string, string> = {
    "zh-CN": "cn",
    "zh-TW": "tw",
  };

  return localeMap[locale] || "en";
};

export const GET_LANGUAGE = () => {
  const { language } = i18n;

  if (language == null) {
    return "en";
  } else if (existLanguage.includes(language)) {
    return language;
  } else if (language.includes("en")) {
    return "en";
  } else {
    return "en";
  }
};

export const GET_USER_INFO = async () => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.get(api_address + "api/user/account", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const GET_TRANSACTION_HISTORY = async () => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.get(api_address + "api/user/allTransaction", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const UPDATE_USER_SMS_NOTIFICATION = async (smsNotification) => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.post(
    api_address + "api/user/updateSmsNotification",
    {
      smsNotification: smsNotification,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const UPDATE_USER_LOGIN_POPUP = async (loginPopUp) => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.post(
    api_address + "api/user/updateLoginPopUp",
    {
      firstLoginPopup: loginPopUp,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const UPDATE_USER_COUNTRY = async (country) => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.post(
    api_address + "api/user/updateCountry",
    {
      country: country,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const UPDATE_USER_TIME_ZONE = async (timeZone) => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.post(
    api_address + "api/user/updateTimeZone",
    {
      timeZone: timeZone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const UPDATE_USER_PHONE_NUMBER = async (phoneNumber, countryCode) => {
  const token = JS_COOKIE.get("token");
  const { data } = await axios.post(
    api_address + "api/user/updatePhoneNumber",
    {
      phoneNumber: phoneNumber,
      countryCode: countryCode,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const objectToArray = (data: [key: string]): string[] => {
  return Object.keys(data).map((key: string) => data[key]);
};
