import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import api_address from "../../../constants/config";
import { useTranslation } from "react-i18next";
import { CURRENCY, PRODUCTS } from "../../../constants/common";
import {
  Container,
  ModalContent,
  Header,
  CloseButton,
  Content,
  TextArea,
  CopyButton,
} from "./style";
import Dropdown from "../DropDown";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { JS_COOKIE } from "util/auth";

interface optionType {
  text: string;
  value: string;
}

interface CouponType {
  couponCode: string;
  currency: string;
  applyTo: Array<string>;
  applyAll: boolean;
}

interface Props {
  showReferralLinkModal: boolean;
  handleCloseReferralLinkModal: () => void;
}

export default function ReferralLinkModal(props: Props) {
  const [productList, setProductList] = useState([] as Array<string>);
  const [couponList, setCouponList] = useState([] as Array<CouponType>);
  const [couponOptions, setCouponOptions] = useState([] as Array<optionType>);
  const [product, setProduct] = useState("" as string);
  const [coupon, setCoupon] = useState("" as string);
  const [currency, setCurrency] = useState("" as string);
  const [referralLink, setReferralLink] = useState("" as string);

  const { t } = useTranslation();

  const productMap = useMemo(() => {
    return t("admin.product", { returnObjects: true });
  }, [t]);

  useEffect(() => {
    const getData = async () => {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const [productData, couponData] = await axios.all([
        axios.get(api_address + "api/admin/coupon/allActiveProduct", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(api_address + "api/admin/coupon/allAvailableCoupon", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);
      const productList = productData.data.products.map(
        (product) => product.name
      );
      const couponList = couponData.data.availableCoupon.map((coupon) => {
        const { couponCode, currency, applyAll } = coupon;
        const applyTo = coupon.applyTo;
        return {
          couponCode,
          currency,
          applyTo,
          applyAll,
        };
      });
      setProductList(productList);
      setCouponList(couponList);
    };
    getData();
  }, [productMap]);

  useEffect(() => {
    const couponOptions = couponList
      .filter(
        (coupon: CouponType) =>
          coupon.currency === currency &&
          (coupon.applyAll || coupon.applyTo.includes(product))
      )
      .map((coupon) => {
        return {
          text: coupon.couponCode,
          value: coupon.couponCode,
        };
      });
    setCouponOptions(couponOptions);
  }, [product, currency, couponList]);

  useEffect(() => {
    let referralLink = "";
    if (product !== "" && currency !== "") {
      referralLink +=
        window.location.origin +
        "/purchases-payment?product=" +
        product +
        "&currency=" +
        currency;
      if (coupon !== "") {
        referralLink += "&coupon=" + coupon;
      }
      if (product == PRODUCTS.CPCBuyUp) {
        referralLink += "&isBuyUp=true";
      }
    }
    setReferralLink(referralLink);
  }, [product, currency, coupon, productMap]);

  const productOptions = productList.map((product: string) => {
    return {
      text: product,
      value: product,
    };
  });

  const currencyOptions = Object.keys(CURRENCY).map((currency: string) => {
    return {
      text: CURRENCY[currency],
      value: CURRENCY[currency],
    };
  });

  const handleProductChange = (product: string) => {
    setProduct(product);
    setCoupon("");
  };

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
    setCoupon("");
  };

  const handleClear = () => {
    setProduct("");
    setCurrency("");
    setCoupon("");
  };

  const handleClose = () => {
    setProduct("");
    setCurrency("");
    setCoupon("");
    props.handleCloseReferralLinkModal();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <Container id="ModalContainer" show={props.showReferralLinkModal}>
      <ModalContent id="ModalContent">
        <Content>
          <Header>Select a product</Header>
          <Dropdown
            componentName="admin - product"
            type="product"
            defaultSelection={product}
            selectedText={product}
            onOptionChange={handleProductChange}
            options={productOptions}
          />
        </Content>
        <Content>
          <Header>Select a currency</Header>
          <Dropdown
            componentName="admin - assign therapist"
            type="currency"
            defaultSelection={currency}
            selectedText={currency}
            onOptionChange={handleCurrencyChange}
            options={currencyOptions}
          />
        </Content>

        {product !== "" && currency !== "" && (
          <Content>
            <Header>Select a coupon</Header>
            <Dropdown
              componentName="admin - assign therapist"
              type="coupon"
              defaultSelection={coupon}
              selectedText={coupon}
              onOptionChange={setCoupon}
              options={couponOptions}
            />
          </Content>
        )}
        <Content>
          <TextArea disabled={true} value={referralLink} />
          <CopyButton onClick={handleCopyLink}>Copy</CopyButton>
        </Content>
        <div style={{ display: "flex" }}>
          <CloseButton onClick={handleClose}>Close</CloseButton>
          <CloseButton onClick={handleClear}>Clear</CloseButton>
        </div>
      </ModalContent>
    </Container>
  );
}
