import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  A4Container,
  SectionTitle,
  ContentText,
  TH,
  Bold,
  HeadTR,
  DegreeTD,
  ReviewTD,
  SmallSectionTitle,
  Table,
  TR,
  TD,
  DescriptionContentTD,
  FirstTD,
  GeneralTD,
  PageNumber,
  WaterMark,
} from "../styles";
import { videoAnalysisType } from "../../data";
import { VIDEO_ANALYSIS_INDEX } from "../../../../../../constants/report";
import { getWatermark } from "../ImageLoader";
interface VideoAnalysisProps {
  language: string;
  videoAnalysis: videoAnalysisType;
}

interface VideoAnalysisState {}

type Props = VideoAnalysisProps & WithTranslation;

class VideoAnalysis extends Component<Props, VideoAnalysisState> {
  overallQualityOfTheVideoTable = () => {
    const { t, videoAnalysis } = this.props;
    const { pictureQuality, audioQuality, naturalness } = videoAnalysis;
    const { overallQuality } = VIDEO_ANALYSIS_INDEX;
    const overallVideoScore = (
      (pictureQuality + audioQuality + naturalness) /
      3
    ).toFixed(2);
    const overallVideoScoreBase =
      (overallQuality.pictureQuality +
        overallQuality.audioQuality +
        overallQuality.naturalness) /
      3;
    return (
      <Table>
        <thead>
          <HeadTR>
            <TH>
              {t(
                "user.survey.corePro.result.videoAnalysis.overallQuality.pictureQuality.title"
              )}
            </TH>
            <TH>
              {t(
                "user.survey.corePro.result.videoAnalysis.overallQuality.audioQuality.title"
              )}
            </TH>
            <TH>
              {t(
                "user.survey.corePro.result.videoAnalysis.overallQuality.naturalness.title"
              )}
            </TH>
            <TH>
              {t(
                "user.survey.corePro.result.videoAnalysis.overallQuality.overall.title"
              )}
            </TH>
          </HeadTR>
        </thead>
        <tbody>
          <TR>
            <GeneralTD>{`${pictureQuality} / ${overallQuality.pictureQuality}`}</GeneralTD>
            <GeneralTD>{`${audioQuality} / ${overallQuality.audioQuality}`}</GeneralTD>
            <GeneralTD>{`${naturalness} / ${overallQuality.naturalness}`}</GeneralTD>
            <GeneralTD>{`${overallVideoScore} / ${overallVideoScoreBase}`}</GeneralTD>
          </TR>
        </tbody>
      </Table>
    );
  };

  temperamentAndLearningCharacteristicsTable = () => {
    const { t } = this.props;

    return (
      <Table>
        <HeadTR>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.table.category"
            )}
          </TH>
          <TH style={{ padding: "5px", textAlign: "center" }}>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.table.degree"
            )}
          </TH>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.table.review"
            )}
          </TH>
        </HeadTR>
        {this.activityLevelAnalysis()}
        {this.sensitivityAnalysis()}
        {this.distractionAnalysis()}
      </Table>
    );
  };

  activityLevelAnalysis = () => {
    const { t, videoAnalysis } = this.props;
    const {
      activityLevel,
      activityLevelOther,
      activityLevelComment,
      activityLevelCommentOther,
    } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.temperamentAndLearningCharacteristic.activityLevel
        .degree - 1;
    const activityLevelReview = this.getTraitAndCharacteristicReview(
      "activityLevel",
      activityLevelComment,
      activityLevelCommentOther
    );
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.activityLevel.title"
          )}
          <DescriptionContentTD>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.activityLevel.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {activityLevel === otherIndex ? (
          <DegreeTD>{activityLevelOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.activityLevel.degree.${activityLevel}`
            )}
          </DegreeTD>
        )}
        <ReviewTD>{activityLevelReview}</ReviewTD>
      </TR>
    );
  };

  sensitivityAnalysis = () => {
    const { t, videoAnalysis } = this.props;
    const {
      sensitivity,
      sensitivityOther,
      sensitivityComment,
      sensitivityCommentOther,
    } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.temperamentAndLearningCharacteristic.sensitivity
        .degree - 1;
    const sensitivityReview = this.getTraitAndCharacteristicReview(
      "sensitivity",
      sensitivityComment,
      sensitivityCommentOther
    );
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.sensitivity.title"
          )}
          <DescriptionContentTD>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.sensitivity.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {sensitivity === otherIndex ? (
          <DegreeTD>{sensitivityOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.sensitivity.degree.${sensitivity}`
            )}
          </DegreeTD>
        )}
        <ReviewTD>{sensitivityReview}</ReviewTD>
      </TR>
    );
  };

  distractionAnalysis = () => {
    const { t, videoAnalysis } = this.props;
    const {
      distraction,
      distractionOther,
      distractionComment,
      distractionCommentOther,
    } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.temperamentAndLearningCharacteristic.distraction
        .degree - 1;
    const distractionReview = this.getTraitAndCharacteristicReview(
      "distraction",
      distractionComment,
      distractionCommentOther
    );
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.distraction.title"
          )}
          <DescriptionContentTD>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.distraction.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {distraction === otherIndex ? (
          <DegreeTD>{distractionOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.distraction.degree.${distraction}`
            )}
          </DegreeTD>
        )}
        <ReviewTD>{distractionReview}</ReviewTD>
      </TR>
    );
  };

  getTraitAndCharacteristicReview = (
    section: string,
    comments: Array<number>,
    otherComment: string
  ) => {
    const { t } = this.props;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.temperamentAndLearningCharacteristic[section]
        .comment - 1;
    return comments.map((comment: number) => {
      return comment === otherIndex ? (
        <div>{otherComment}</div>
      ) : (
        <div>
          {t(
            `user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.${section}.comment.${comment}`
          )}
        </div>
      );
    });
  };

  SpeechLanguageAndCommunicationReviewTable = () => {
    const { t } = this.props;
    return (
      <Table>
        <HeadTR>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.table.category"
            )}
          </TH>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.table.degree"
            )}
          </TH>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.table.review"
            )}
          </TH>
        </HeadTR>
        {this.speechClarity()}
        {this.sentenceLength()}
        {this.communication()}
      </Table>
    );
  };

  speechClarity = () => {
    const { t, videoAnalysis } = this.props;
    const {
      speechClarity,
      speechClarityOther,
      speechClarityComment,
      speechClarityCommentOther,
    } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.speechLanguageAndCommunication.speechClarity.degree -
      1;
    const speechClarityReview = this.getSpeechLanguageAndCommunicationReview(
      "speechClarity",
      speechClarityComment,
      speechClarityCommentOther
    );
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.speechClarity.title"
          )}
        </FirstTD>
        {speechClarity === otherIndex ? (
          <TD>{speechClarityOther}</TD>
        ) : (
          <TD>
            {t(
              `user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.speechClarity.degree.${speechClarity}`
            )}
          </TD>
        )}
        <ReviewTD>{speechClarityReview}</ReviewTD>
      </TR>
    );
  };

  sentenceLength = () => {
    const { t, videoAnalysis } = this.props;
    const {
      sentenceLength,
      sentenceLengthOther,
      sentenceLengthComment,
      sentenceLengthCommentOther,
    } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.speechLanguageAndCommunication.sentenceLength
        .degree - 1;
    const sentenceLengthReview = this.getSpeechLanguageAndCommunicationReview(
      "sentenceLength",
      sentenceLengthComment,
      sentenceLengthCommentOther
    );
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.sentenceLength.title"
          )}
        </FirstTD>
        {sentenceLength === otherIndex ? (
          <TD>{sentenceLengthOther}</TD>
        ) : (
          <TD>
            {t(
              `user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.sentenceLength.degree.${sentenceLength}`
            )}
          </TD>
        )}
        <ReviewTD>{sentenceLengthReview}</ReviewTD>
      </TR>
    );
  };

  communication = () => {
    const { t, videoAnalysis } = this.props;
    const {
      communication,
      communicationOther,
      communicationComment,
      communicationCommentOther,
    } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.speechLanguageAndCommunication.communication.degree -
      1;
    const communicationReview = this.getSpeechLanguageAndCommunicationReview(
      "communication",
      communicationComment,
      communicationCommentOther
    );
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.communication.title"
          )}
        </FirstTD>
        {communication === otherIndex ? (
          <TD>{communicationOther}</TD>
        ) : (
          <TD>
            {t(
              `user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.communication.degree.${communication}`
            )}
          </TD>
        )}
        <ReviewTD>{communicationReview}</ReviewTD>
      </TR>
    );
  };

  getSpeechLanguageAndCommunicationReview = (
    section: string,
    comments: Array<number>,
    otherComment: string
  ) => {
    const { t } = this.props;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.speechLanguageAndCommunication[section].comment - 1;
    return comments.map((comment: number) => {
      return comment === otherIndex ? (
        <div>{otherComment}</div>
      ) : (
        <div>
          {t(
            `user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.${section}.comment.${comment}`
          )}
        </div>
      );
    });
  };

  interactionAndParentalGuidanceSkillsTable = () => {
    const { t } = this.props;
    return (
      <Table>
        <HeadTR>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.table.category"
            )}
          </TH>
          <TH style={{ padding: "5px", textAlign: "center" }}>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.table.degree"
            )}
          </TH>
          <TH>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.table.overall"
            )}
          </TH>
        </HeadTR>
        {this.qualityOfInteraction()}
        {this.encouragement()}
        {this.qualityOfGuide()}
        {this.emotion()}
      </Table>
    );
  };

  qualityOfInteraction = () => {
    const { t, videoAnalysis } = this.props;
    const { qualityOfInteraction, qualityOfInteractionOther } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.qualityOfInteraction.degree -
      1;
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfInteraction.title"
          )}
          <DescriptionContentTD>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfInteraction.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {qualityOfInteraction === otherIndex ? (
          <DegreeTD>{qualityOfInteractionOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfInteraction.degree.${qualityOfInteraction}`
            )}
          </DegreeTD>
        )}
        {this.qualityOfInteractionOverall()}
      </TR>
    );
  };

  qualityOfGuideOverall = () => {
    const { t, videoAnalysis } = this.props;
    const {
      qualityOfGuideComment,
      qualityOfGuideCommentOther,
      qualityOfGuideImprovement,
      qualityOfGuideImprovementOther,
    } = videoAnalysis;
    const improvements =
      this.getInteractionAndParentalGuidanceSkillsImprovement(
        "qualityOfGuide",
        qualityOfGuideImprovement,
        qualityOfGuideImprovementOther
      );
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.qualityOfGuide.comment;
    return (
      <ReviewTD>
        {qualityOfGuideComment === otherIndex ? (
          <div>{qualityOfGuideCommentOther}</div>
        ) : (
          <div>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfGuide.comment.${qualityOfGuideComment}`
            )}
          </div>
        )}
        <Bold>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfGuide.improvement.title"
          )}
        </Bold>
        <div>{improvements}</div>
      </ReviewTD>
    );
  };

  encouragement = () => {
    const { t, videoAnalysis } = this.props;
    const { encouragement, encouragementOther } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.encouragement.degree - 1;
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.encouragement.title"
          )}
          <DescriptionContentTD>
            {" "}
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.encouragement.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {encouragement === otherIndex ? (
          <DegreeTD>{encouragementOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.encouragement.degree.${encouragement}`
            )}
          </DegreeTD>
        )}
        {this.encouragementOverall()}
      </TR>
    );
  };

  encouragementOverall = () => {
    const { t, videoAnalysis } = this.props;
    const {
      encouragementComment,
      encouragementCommentOther,
      encouragementImprovement,
      encouragementImprovementOther,
    } = videoAnalysis;
    const improvements =
      this.getInteractionAndParentalGuidanceSkillsImprovement(
        "encouragement",
        encouragementImprovement,
        encouragementImprovementOther
      );
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.encouragement.comment - 1;
    return (
      <ReviewTD>
        {encouragementComment === otherIndex ? (
          <div>{encouragementCommentOther}</div>
        ) : (
          <div>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.encouragement.comment.${encouragementComment}`
            )}
          </div>
        )}
        <Bold>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.encouragement.improvement.title"
          )}
        </Bold>
        <div>{improvements}</div>
      </ReviewTD>
    );
  };

  qualityOfGuide = () => {
    const { t, videoAnalysis } = this.props;
    const { qualityOfGuide, qualityOfGuideOther } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.qualityOfGuide.degree - 1;
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfGuide.title"
          )}
          <DescriptionContentTD>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfGuide.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {qualityOfGuide === otherIndex ? (
          <DegreeTD>{qualityOfGuideOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfGuide.degree.${qualityOfGuide}`
            )}
          </DegreeTD>
        )}
        {this.qualityOfGuideOverall()}
      </TR>
    );
  };

  qualityOfInteractionOverall = () => {
    const { t, videoAnalysis } = this.props;
    const {
      qualityOfInteractionComment,
      qualityOfInteractionCommentOther,
      qualityOfInteractionImprovement,
      qualityOfInteractionImprovementOther,
    } = videoAnalysis;
    const improvements =
      this.getInteractionAndParentalGuidanceSkillsImprovement(
        "qualityOfInteraction",
        qualityOfInteractionImprovement,
        qualityOfInteractionImprovementOther
      );
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.qualityOfInteraction.comment -
      1;
    return (
      <ReviewTD>
        {qualityOfInteractionComment === otherIndex ? (
          <div>{qualityOfInteractionCommentOther}</div>
        ) : (
          <div>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfInteraction.comment.${qualityOfInteractionComment}`
            )}
          </div>
        )}
        <Bold>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.qualityOfInteraction.improvement.title"
          )}
        </Bold>
        <div>{improvements}</div>
      </ReviewTD>
    );
  };

  emotion = () => {
    const { t, videoAnalysis } = this.props;
    const { emotion, emotionOther } = videoAnalysis;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance.emotion.degree - 1;
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.emotion.title"
          )}
          <DescriptionContentTD>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.emotion.description"
            )}
          </DescriptionContentTD>
        </FirstTD>
        {emotion === otherIndex ? (
          <DegreeTD>{emotionOther}</DegreeTD>
        ) : (
          <DegreeTD>
            {t(
              `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.emotion.degree.${emotion}`
            )}
          </DegreeTD>
        )}
        {this.emotionOverall()}
      </TR>
    );
  };

  emotionOverall = () => {
    const { t, videoAnalysis } = this.props;
    const { emotionDescription, emotionImprovement, emotionImprovementOther } =
      videoAnalysis;
    const improvements =
      this.getInteractionAndParentalGuidanceSkillsImprovement(
        "emotion",
        emotionImprovement,
        emotionImprovementOther
      );
    return (
      <ReviewTD>
        <div>{emotionDescription}</div>
        <Bold>
          {t(
            "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.emotion.improvement.title"
          )}
        </Bold>
        <div>{improvements}</div>
      </ReviewTD>
    );
  };

  getInteractionAndParentalGuidanceSkillsImprovement = (
    section: string,
    improvements: Array<number>,
    otherImprovements: string
  ) => {
    const { t } = this.props;
    const otherIndex =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance[section].improvement - 1;
    if (improvements.length === 0) {
      return (
        <div>
          {t(
            `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.${section}.improvement.none`
          )}
        </div>
      );
    }
    return improvements.map((improvement: number) => {
      return improvement === otherIndex ? (
        <div>{otherImprovements}</div>
      ) : (
        <div>
          {t(
            `user.survey.corePro.result.videoAnalysis.interactionAndGuidance.${section}.improvement.${improvement}`
          )}
        </div>
      );
    });
  };

  render = () => {
    const { t } = this.props;

    return (
      <>
        <A4Container>
          <SectionTitle>
            {t("user.survey.corePro.result.videoAnalysis.title")}
          </SectionTitle>
          <SmallSectionTitle>
            {t("user.survey.corePro.result.videoAnalysis.overallQuality.title")}
          </SmallSectionTitle>
          <ContentText>
            {t(
              "user.survey.corePro.result.videoAnalysis.overallQuality.description"
            )}
          </ContentText>
          {this.overallQualityOfTheVideoTable()}

          <SmallSectionTitle>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.title"
            )}
          </SmallSectionTitle>
          <ContentText>
            {t(
              "user.survey.corePro.result.videoAnalysis.temperamentAndLearningCharacteristic.description"
            )}
          </ContentText>
          {this.temperamentAndLearningCharacteristicsTable()}
          <PageNumber>6</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
        <A4Container>
          <SmallSectionTitle>
            {t(
              "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.title"
            )}
          </SmallSectionTitle>
          <ContentText>
            {t(
              "user.survey.corePro.result.videoAnalysis.speechLanguageAndCommunication.description"
            )}
          </ContentText>
          {this.SpeechLanguageAndCommunicationReviewTable()}
          <PageNumber>7</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
        <A4Container>
          <SmallSectionTitle>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.title"
            )}
          </SmallSectionTitle>
          <ContentText>
            {t(
              "user.survey.corePro.result.videoAnalysis.interactionAndGuidance.description"
            )}
          </ContentText>
          {this.interactionAndParentalGuidanceSkillsTable()}
          <PageNumber>8</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
      </>
    );
  };
}
export default withTranslation()(VideoAnalysis);
