import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0%;
  left:0%;
  width 100%;
  height: 100%;
  display: ${(props: { show: boolean }) => (props.show ? "block" : "none")};
  background: rgba(0, 0, 0, 0.95);
  overscroll-behavior: contain;
  overflow: auto;
  padding-top: 100px;
`;

export const ModalContent = styled.div`
  border-radius: 15px;
  background-color: white;
  padding: 0 10px;
  margin: auto;
  width: 80%;
  overflow: auto;
  height: 600px;
`;

export const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CloseButton = styled.div`
  flex: 1 1 150px;
  margin: 5px;
  display: grid;
  place-items: center;
  height: 50px;
  color: black;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  background-color: #f9fff6;
  border: solid 1px #a8bf96;
  border-radius: 10px;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 60px;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 450px;
  border: solid 1px #a8bf96;
  width: 50%;
  margin: 0 10px;
`;

export const UploadBox = styled.div`
  margin: 19px 40px 0px 40px;
  border: 1px dashed rgb(204, 204, 204);
  border-radius: 10px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    margin: 19px 10px 0px 10px;
  }
`;

export const UploadInput = styled.input`
  display: none;
`;

export const UploadButton = styled.label<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 28px 28px;
  display: inline-block;
  margin: 21px 0px;
  cursor: pointer;
  height: 28px;
`;

export const UploadButtonText = styled.div`
  margin-left: 39px;
  font-size: 14px;
  font-family: Noto Sans CJK TC Medium;
  text-align: start;
  line-height: 28px;
`;

export const EvaluationInput = styled.input`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const Asterisk = styled.span`
  color: red;
  margin-left: 2px;
`;

export const EvaluationLabel = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

export const UploadContainer = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
`;

export const ProgressBarContainer = styled.div`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
`;

export const ProgressBarLine = styled.div`
  border-radius: 15px;
  height: 8px;
  border: solid 8px #9d9d9d;
`;

export const BarLine = styled.div<{ barWidth: string }>`
  border-radius: 15px;
  height: 8px;
  border: solid 8px #6898e3;
  width: ${(props) => props.barWidth};
  margin-top: -16px;
`;

export const Video = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: #f9fff6;
  border: solid 1px #a8bf96;
  border-radius: 10px;
  margin: 0 10px;
  color: #000000;
  height: 50px;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
`;

export const Comment = styled.div`
  border: solid 1px #a8bf96;
  border-radius: 10px;
  color: #000000;
  margin: 0 10px;
  padding: 10px;
  width: 100%;
`;
