import styled from "styled-components";

const StyledImage = styled.img`
  transition: transform 0.3s ease; /* Add a smooth transition effect */

  /* Define styles for when the image is hovered */
  &:hover {
    transform: scale(1.2); /* Enlarge the image by 20% on hover */
  }
`;

export const ContactUsIcon = styled(StyledImage)`
  width: 89px;
  height: 89px;
  margin: 8px;
  border-radius: 20px;
`;

export const ContactUsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AdminRouteContainer = styled.div`
  background: white;
  @media only screen and (max-width: 575px) {
    margin-left: 0px;
    // padding-bottom: 50px;
  }
  @media only screen and (min-width: 576px) {
    margin-left: 0px;
    // padding-bottom: 50px;
  }
  @media only screen and (min-width: 768px) {
    margin-left: 220px;
    padding-bottom: 0px;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 220px;
    padding-bottom: 0px;
  }
  @media only screen and (min-width: 1200px) {
    margin-left: 220px;
    padding-bottom: 0px;
  }
`;
export const RouteContainer = styled.div`
  background: white;
`;
export const RouteSectionContainer = styled.div`
  textalign: -webkit-center;
  @media only screen and (max-width: 575px) {
    padding: 20px 2%;
  }
  @media only screen and (min-width: 576px) {
    padding: 20px 10%;
  }
  @media only screen and (min-width: 768px) {
    padding: 20px 8%;
  }
  @media only screen and (min-width: 992px) {
    padding: 20px 15%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 20px 15%;
  }
`;

// font-size from guide system

// navbar
export const NavbarLabel = styled.div<{ isSelect: boolean }>`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: ${(props) => (props.isSelect ? "500" : "400")};
  color: ${(props) => (props.isSelect ? "#030303" : "#606060")};
`;
export const SmallNavbarLabel = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
`;

// title
export const H1Title = styled.div`
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.2rem;
  color: #030303;
`;
export const H2Title = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  color: #030303;
`;

// section title
export const SectionTitle = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 400;
  // color: #030303;
  margin-bottom: 1rem;
`;
// export const MediumSectionTitle = styled.div`
//   font-size: 1.8rem;
//   font-weight: 400;
//   line-height: 2.4rem;
// `;
export const SmallSectionTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: normal;
  margin-bottom: 1rem;
`;

// video or article title
export const ContentTitle = styled.div`
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 400;
  color: #030303;
`;
export const SmallContentTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  color: #030303;
`;

// Badge
export const BadgeTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: normal;
`;
export const SmallBadgeTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: unset;
`;

// hashtag
export const MiniAttribution = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

// description
export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: #606060;
`;
export const SmallDescription = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #606060;
`;

// user comment message
export const UserComment = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: normal;
`;
export const CommentLink = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #606060;
`;

// link
export const Link = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: normal;
`;

// Action button
// navbar title, tab title, subscribe button
export const ActionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.007px;
`;
export const SmallActionTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #606060;
`;

// // 13px
// export const ThumbnailAttribution = styled.div`
//   font-size: 1.3rem;
//   font-weight: 400;
//   line-height: 1.8rem;
//   letter-spacing: inherit;
//   text-transform: none;
// `;
// export const Caption = styled.div`
//   font-size: 1.3rem;
//   line-height: normal;
//   font-weight: 500;
//   letter-spacing: 0.007px;
//   text-transform: uppercase;
// `;

// append
export const FooterText = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #909090;
`;

// general segment
export const GeneralSegment = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
`;

export const CenterSegment = styled.div`
  display: flex;
  justify-content: center;
`;

// tab component
export const ActivitiesGoalsMenu = styled.div`
  width: 100%;
  padding: 5px 0;
`;
export const ActivitiesGoalsMenuItem = styled.div<{ active: boolean }>`
  display: inline-block;
  text-align: center;
  border-bottom: ${({ active }) => (active ? "3px solid #6898E3" : "none")};
  margin: 0 8px;
  color: ${({ active }) => (active ? "#6898E3" : "grey")};
  padding: 3px;
  cursor: pointer;
`;

// modal
export const ModalMask = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  background-color: #00000090;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
`;
export const ModalContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  background-color: white;
  border-radius: 15px;
  height: max-content;
  max-height: 95vh;
  overflow: auto;
  width: max-content;
  position: relative;
  padding: 40px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    padding: 40px 40px;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 50px;
  }
`;
export const ModalContainerWithClose = styled.div`
  height: max-content;
  width: max-content;
  padding: 40px 30px;
  margin: 2rem;
  @media only screen and (min-width: 768px) {
    padding: 40px 40px;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 50px;
  }
`;
export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  color: white;
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
`;
export const ContainerWidth = styled.div`
  width: 300px;
  @media only screen and (min-width: 768px) {
    width: 330px;
  }
  @media only screen and (min-width: 992px) {
    width: 360px;
  }
  @media only screen and (min-width: 1200px) {
    width: 400px;
  }
`;
export const ModalHeader = styled(ContainerWidth)`
  margin-bottom: 20px;
  text-align: center;
`;
export const HeaderTitle = styled(H2Title)`
  color: #6898e3;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

  @media (min-width: 768px) {
    text-align: center;
  }
`;
export const HeaderDescription = styled(Description)`
  margin-top: 10px;
`;
export const ModalContent = styled(ContainerWidth)`
  overflow: auto;
  overflow-y: hidden;
  max-height: 400px;
`;
export const ContentText = styled(Description)`
  color: #030303;
  text-align: center;
  font-size: 16px;
  line-height: normal;

  @media (min-width: 768px) {
    text-align: center;
  }
`;
export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

// button
export const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 50px;
  padding: 0 40px 0 40px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;

// Change to styled(ButtonStyleRounded) if rounded buttons are needed
export const ButtonStyleRounded = styled(ButtonStyle)`
  border-radius: 50px;
`;

export const NextButton = styled(ButtonStyle)<{ status: boolean }>`
  background: ${({ status }) => (status ? "#6898e3" : "#E8E9E9")};
  pointer-events: ${({ status }) => (status ? "cursor" : "none")};
  color: ${({ status }) => (status ? "white" : "#00000085")};
  :hover {
    box-shadow: ${({ status }) =>
      status
        ? "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        : "none"};
  }
`;

export const HomeButton = styled(NextButton)`
  margin: 5px 0 0 0;
  padding: 0 125px 0 125px;
`;

export const ShowErrNextButton = styled(ButtonStyle)<{
  status: boolean;
}>`
  background: ${({ status }) => (status ? "#6898e3" : "white")};
  color: ${({ status }) => (status ? "white" : "#6898e3")};
  border: solid 1px #6898e3;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;
export const CheckoutButtonStyle = styled(ButtonStyle)`
  background-color: #242424;
  color: #ffffff;
  white-space: pre;
  @media only screen and (max-width: 575px) {
    position: fixed;
    width: 92%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 15px 4%;
  }
  @media only screen and (min-width: 576px) {
    position: fixed;
    width: 92%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 15px 4%;
  }
  @media only screen and (min-width: 768px) {
    position: relative;
    bottom: auto;
    width: 100%;
    margin: 30px 0 20px 0;
  }
  @media only screen and (min-width: 992px) {
    position: relative;
    width: 100%;
    bottom: auto;
    margin: 30px 0 20px 0;
  }
  @media only screen and (min-width: 1200px) {
    position: relative;
    width: 100%;
    bottom: auto;
    margin: 30px 0 20px 0;
  }
`;
export const CheckoutButton = styled(CheckoutButtonStyle)`
  @media only screen and (min-width: 768px) {
    width: 35%;
  }
  @media only screen and (min-width: 992px) {
    width: 35%;
  }
  @media only screen and (min-width: 1200px) {
    width: 35%;
  }
`;

export const CentralisingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
`;

export const JustifyRightDiv = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  height: auto;
  width: 100%;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  } ;
`;

export const JustifyRightDivForButtons = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  height: auto;
  width: 100%;
`;

export const JustifyLeftDivForButtons = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  height: auto;
  width: 100%;
`;

export const NextAndPreviousButtonContainer = styled.div`
  display: flex;
`;

// export const NextButton = styled(ButtonStyle)<{ status: boolean }>``
export const UploadVideoButton = styled(ShowErrNextButton)<{
  disableButton: boolean;
}>`
  border: none;
  border-radius: 50px;
  pointer-events: ${(props) => (props.disableButton ? "none" : "inherit")};
  background-color: ${(props) => (props.disableButton ? "#C4C4C4" : "#6898e3")};
  color: white;

  @media only screen and (max-width: 400px) {
    font-size: 14px;
    padding: 0 20px 0 20px;
  }
`;

export const PayButton = styled(CheckoutButtonStyle)<{ disabled: boolean }>`
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  disabled: ${(props) => (props.disabled ? "true" : "false")};
`;

export const PayLoader = styled.div`
  position: absolute;
  right: 30px;
  border: 1px solid #242424;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ApplyCouponButton = styled(ButtonStyle)`
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  line-height: 20px;
  color: white;
  background: #242424;
`;

// empty state
export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 500px;
  padding: 0 18%;
  text-align: center;
`;
export const EmptyStateImg = styled.img``;
export const EmtpyStateH1 = styled(SectionTitle)`
  margin: 30px 0 0 0;
`;
export const EmtpyStateContent = styled(Description)`
  margin: 15px 0 0 0;
`;
export const EmptyStateButton = styled(ButtonStyle)`
  height: 40px;
  background: #6898e3;
  color: white;
`;
