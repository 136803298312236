import styled from "styled-components";

// media query
export const ContainerMediaQuery = styled.div`
  @media only screen and (max-width: 575px) {
    width: 95%;
    padding-left: 15%;
  }
  @media only screen and (min-width: 576px) {
    width: 95%;
    padding-left: 15%;
  }
  @media only screen and (min-width: 768px) {
    width: 95%;
    padding-left: 13%;
  }
  @media only screen and (min-width: 992px) {
    width: 95%;
    padding-left: 13%;
  }
  @media only screen and (min-width: 1200px) {
    width: 95%;
    padding-left: 13%;
  }
`;
export const MediaQuery = styled.div`
  @media only screen and (max-width: 575px) {
    width: 40px;
    font-size: 10px;
  }
  @media only screen and (min-width: 576px) {
    width: 40px;
    font-size: 10px;
  }
  @media only screen and (min-width: 768px) {
    width: 50px;
    font-size: 14px;
  }
  @media only screen and (min-width: 992px) {
    width: 50px;
    font-size: 14px;
  }
  @media only screen and (min-width: 1200px) {
    width: 50px;
    font-size: 16px;
  }
`;

// Container
export const Container = styled.div`
  width: 100%;
  min-height: 450px;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  padding: 20px 0;
`;

export const ChartFrontContainer = styled(ContainerMediaQuery)`
  display: flex;
  justify-content: space-around;
  // width: max-content;
`;
export const ChartContainer = styled.div`
  margin: 20px 0;
`;
export const ResultBarHeight = 275;

// chart background
export const ChartBackgroundContainer = styled.div`
  z-index: 0;
  margin-top: -349px;
`;
export const ChartBackgroundRangeContainer = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  height: 25px;
`;
export const ChartBackgroundRangeLine = styled.div`
  border-top: 1px solid #f2f2f2;
  width: 100%;
  opacity: 30%;
`;

// result bar
export const ChartFrontBar = styled(MediaQuery)<{ isOpen: boolean }>`
  display: grid;
  place-items: ${({ isOpen }) => (isOpen ? "baseline" : "center")};
  height: ${ResultBarHeight}px;
  background-color: #f0f1f4;
  border-radius: 0px 0px 0px 0px;
  margin: 0 auto;
`;
export const ChartFrontBarCover = styled(MediaQuery)<{
  color: string;
  height: string;
}>`
  position: relative;
  border-radius: ${({ height }) =>
    height === `${ResultBarHeight}` ? "10px 10px 0px 0px" : "0"};
  top: ${({ height }) => ResultBarHeight - parseInt(height) + "px"};
  height: ${({ height }) => height + "px"};
  background-color: ${({ color }) => color};
`;

export const ChartResultContainer = styled.div`
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  white-space: pre;
  color: #000000;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
`;

export const ChartResultText = styled(MediaQuery)<{ color: string }>`
  color: ${({ color }) => color};
  margin-bottom: 10px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartResultImg = styled.img`
  margin: 10px auto 10px auto;
  width: 35px;
`;

export const ChartBackgroundRangeText = styled(MediaQuery)`
  position: relative;
  top: -10px;
  display: flex;
  align-items: center;
`;

export const Lock = styled.img`
  width: 75%;
`;
