import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../common/NotificationModal";
import Pagination from "../../common/Pagination";
import SearchInput from "../../common/SearchInput";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { getPageStartAndEnd, debounce } from "../../../util/admin";
import {
  Container,
  Table,
  Header,
  Row,
  ToolContainer,
  Page,
  Button,
  LinkButton,
} from "../styles";
import { JS_COOKIE } from "util/auth";

interface resultType {
  id: string;
  transactionId: string;
  currentStep: string;
  completed: string;
  userId: string;
  productName: string;
}

interface ResultProps {
  history: History<LocationState>;
}

interface ResultStates {
  result: Array<resultType>;
  rowCount: number;
  pageCount: number;
  offset: number;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  selectedItem: number;
  queryString: string;
}

type Props = ResultProps & WithTranslation;
class Result extends Component<Props, ResultStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      result: [],
      rowCount: 0,
      pageCount: 0,
      offset: 0,
      message: "",
      showModal: false,
      notificationStatus: false,
      selectedItem: -1,
      queryString: "",
    };
  }

  componentDidMount = () => {
    this.getResultData(0);
  };

  componentDidUpdate = (
    _: ResultProps,
    { offset: prevOffset, queryString: preQueryString }: ResultStates
  ) => {
    const { offset, queryString } = this.state;
    if (prevOffset !== offset || preQueryString !== queryString) {
      this.getResultData(1000);
    }
  };

  getResultData = (time: number) =>
    debounce(async () => {
      try {
        const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
        const { offset, queryString } = this.state;
        const {
          data: { result, rowCount },
        } = await axios.get(api_address + "api/admin/result", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter: queryString,
            offset: offset * LIMIT,
            limit: LIMIT,
          },
        });
        const pageCount = Math.ceil(rowCount / LIMIT) - 1;
        this.setState({
          result: result,
          rowCount: rowCount,
          pageCount: pageCount,
        });
      } catch (err) {
        console.error(err);
      }
    }, time)();

  handlePageChange = (offset: number) => {
    this.setState({ offset: offset });
  };

  onRowClick = (index: number) => {
    const { selectedItem } = this.state;
    if (selectedItem === index) {
      this.setState({ selectedItem: -1 });
    } else {
      this.setState({ selectedItem: index });
    }
  };

  onEditClick = () => {
    const { selectedItem, result } = this.state;
    const ID = result[selectedItem].id;
    this.props.history.push("/admin/result/edit/" + ID);
  };

  tableData = () => {
    const { result, selectedItem } = this.state;
    const { t } = this.props;
    return result.map((result: resultType, index: number) => {
      const { id, transactionId, currentStep, completed, userId, productName } =
        result;
      const isSelected = selectedItem === index;
      return (
        <Row
          key={index}
          isSelected={isSelected}
          onClick={() => this.onRowClick(index)}
        >
          <td>{id}</td>
          <td>{transactionId}</td>
          <td>{currentStep}</td>
          <td>{completed ? "Yes" : "No"}</td>
          <td>
            <LinkButton
              isDisabled={false}
              onClick={() => {
                this.props.history.push("/admin/user/edit/" + userId);
              }}
            >
              {userId}
            </LinkButton>
          </td>
          <td>{productName}</td>
        </Row>
      );
    });
  };

  handleQueryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      queryString: event.target.value,
    });
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      result,
      rowCount,
      pageCount,
      offset,
      selectedItem,
      queryString,
    } = this.state;

    const { pageStart, pageEnd } = getPageStartAndEnd(offset, result.length);

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <ToolContainer>
          <SearchInput
            queryString={queryString}
            placeholder="transactionId"
            handleQueryInput={this.handleQueryInput}
          />
          {selectedItem !== -1 && (
            <Button onClick={this.onEditClick}>Edit</Button>
          )}
        </ToolContainer>
        <Table id="result">
          <tbody>
            <Header>
              <th>{t("admin.result.id")}</th>
              <th>{t("admin.result.transactionId")}</th>
              <th>{t("admin.result.currentStep")}</th>
              <th>{t("admin.result.completed")}</th>
              <th>{t("admin.result.userId")}</th>
              <th>{t("admin.result.productName")}</th>
            </Header>
            {this.tableData()}
          </tbody>
        </Table>
        <ToolContainer>
          <Pagination
            selectedItem={offset}
            pageCount={pageCount}
            handlePageChange={this.handlePageChange}
          />
          <Page>{`${pageStart} - ${pageEnd} of ${rowCount}`}</Page>
        </ToolContainer>
      </Container>
    );
  };
}

export default withTranslation()(Result);
