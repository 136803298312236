import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import DropDown from "../../../common/DropDown";
import { getCountryCodeList } from "../../../../constants/countryCode";
import {
  IS_NUMBER,
  IS_VALID_EMAIL,
  NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE,
} from "../../../../util/common";
import addFile from "../../../User/Evaluation/CorePro/UploadVideoSection/images/AddFile-2x.png";
import fileSuccess from "../../../User/Evaluation/CorePro/UploadVideoSection/images/FileSuccess-2x.png";
import { EDIT_USER_INFORMATION } from "../../../../graphql/mutations/editUserInformation";
import { client } from "../../../../graphql/ApolloClient";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import {
  // ResetPasswordButton, RestLink
  Title,
  Table,
  ResultHeader,
  ResultRow,
  PaymentHeader,
  PaymentRow,
  Item,
  TimeItem,
  AssessmentRow,
  AssessmentHeader,
} from "./styles";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../../util/common";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import {
  UploadInput,
  UploadButton,
  UploadButtonText,
  EvaluationInput,
  UploadContainer,
  SubmitButton,
  EvaluationLabel,
  Asterisk,
} from "components/common/VideoModal/style";
import Uploader from "util/uploader";
import { Button, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  AWS_USER_ASSESSMENTS_FOLDER_PATH,
  handleOpenPDFFromAWS,
} from "components/User/Dashboard/SurveyHistory/UserAssessment";
import { handleSendNewReportEmail } from "./components/newUploadReportEmailSender";

const { confirm } = Modal;

interface Match {
  id: string;
}

interface ResultType {
  id: string;
  transactionId: string;
  product: string;
  currentStep: string;
  completed: boolean;
  startAt: string;
  finishAt: string;
}

interface PaymentType {
  id: string;
  transactionId: string;
  name: string;
  currency: string;
  paymentMethod: string;
  price: string;
  status: string;
  createdAt: string;
}

interface UserEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface UserEditStates {
  [key: string]:
    | string
    | boolean
    | ResultType[]
    | PaymentType[]
    | null
    | number;
  UUID: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  language: string;
  phone: string;
  email: string;
  createdAt: string;
  lastLoginAt: string;
  resetLink: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  resultList: ResultType[];
  paymentList: PaymentType[];
  userAssessmentList: any;
  shouldRefetchUserData: boolean;
  evaluationName: string;
  evaluationDate: string;
  evaluationAgency: string;
  file: any;
  uploadProgress: number;
  AWSFolderName: string;
}

type Props = UserEditProps & WithTranslation;
class UserEdit extends Component<Props, UserEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      UUID: "",
      firstName: "",
      lastName: "",
      countryCode: "",
      language: "",
      phone: "",
      email: "",
      createdAt: "",
      lastLoginAt: "",
      resetLink: "",
      message: "",
      showModal: false,
      notificationStatus: false,
      resultList: [],
      paymentList: [],
      userAssessmentList: [],
      evaluationName: "",
      evaluationDate: "",
      evaluationAgency: "",
      shouldRefetchUserData: false,
      file: null,
      uploadProgress: 0,
      AWSFolderName: "",
    };
  }

  updateInfoOnGuide = async (userUuid, attribute, value) => {
    await client.mutate({
      mutation: EDIT_USER_INFORMATION,
      variables: {
        userUuid: userUuid,
        attribute: attribute,
        value: value.toString(),
      },
    });
  };

  fetchUserData = async (userId) => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { data } = await axios.get(
        `${api_address}api/admin/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { user, resultList, paymentList, userAssessmentList } = data;

      this.setState({
        UUID: user.UUID,
        firstName: user.firstName,
        lastName: user.lastName,
        countryCode: user.countrycode,
        language: user.language,
        phone: user.phone,
        email: user.email,
        createdAt: user.createdat,
        lastLoginAt: user.lastloginat,
        resultList: resultList.reverse(),
        paymentList: paymentList.reverse(),
        userAssessmentList: userAssessmentList.reverse(),
        shouldRefetchUserData: false, // Reset refetch flag after data is fetched
        AWSFolderName: `${AWS_USER_ASSESSMENTS_FOLDER_PATH + user.UUID}`,
      });
    } catch (err: any) {
      console.error("Failed to fetch user data", err);
      this.showNotification("Failed to load user data: " + err.message, false);
    }
  };

  componentDidMount() {
    this.fetchUserData(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const currentUserId = this.props.match.params.id;
    const previousUserId = prevProps.match.params.id;

    if (currentUserId !== previousUserId || this.state.shouldRefetchUserData) {
      this.fetchUserData(currentUserId);
    }
  }

  handleResetPassword = async () => {
    try {
      const userData = {
        email: this.state.email,
      };
      const res = await axios.post(api_address + "api/user/forgot", userData);
      const { status, message, resetLink } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.setState({ resetLink });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
  };

  handleDeleteAssessment = async (assessmentId: number) => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    return axios
      .delete(`${api_address}api/admin/user/assessment/${assessmentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { status, message } = res.data;
        this.setState({ shouldRefetchUserData: true });
        return status;
      });
  };

  handleFileChange = (e) => {
    const { files } = e.currentTarget;
    const { AWSFolderName } = this.state;
    if (!files || files.length === 0) return;
    const file = files[0];

    Uploader(file, AWSFolderName, file.name)
      .on("httpUploadProgress", (evt) => {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        this.setState({ uploadProgress: uploaded });
      })
      .send(function (err, data) {
        if (err) {
          return;
        }
      });
    this.setState({ file });
  };

  handleSubmit = async () => {
    const {
      file,
      evaluationName,
      evaluationDate,
      evaluationAgency,
      email,
      language,
    } = this.state;
    const { id: userId } = this.props.match.params;

    if (!file) {
      console.log("No file uploaded");
      return;
    }

    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const data = {
      fileName: file.name,
      userId: userId,
      evaluationName,
      evaluationDate,
      evaluationAgency,
    };

    try {
      const res = await axios.post(
        api_address + "api/admin/user/assessment/",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;

      this.setState({
        shouldRefetchUserData: true,
        file: null,
        evaluationDate: "",
        evaluationName: "",
        evaluationAgency: "",
        uploadProgress: 0,
      });
      if (status) {
        handleSendNewReportEmail(
          NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE(language),
          email
        );
      }
      return status;
    } catch (error) {
      console.error("Failed to submit assessment:", error);
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  validation = () => {
    const { email, phone, countryCode, firstName } = this.state;
    if (firstName === "") {
      throw new Error("first name is missing");
    }
    if (countryCode === "") {
      throw new Error("country code is missing");
    }
    if (phone === "") {
      throw new Error("phone number is missing");
    }
    if (!IS_NUMBER(phone)) {
      throw new Error("phone number is invalid");
    }
    if (email === "") {
      throw new Error("email is missing");
    }
    if (!IS_VALID_EMAIL(email)) {
      throw new Error("email is invalid");
    }
  };

  onCancelClick = () => {
    this.props.history.push("/admin/user");
  };

  onSaveClick = async () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { UUID, firstName, lastName, countryCode, phone, email } =
        this.state;
      const res = await axios.put(
        api_address + "api/admin/user/" + id,
        { UUID, firstName, lastName, countryCode, phone, email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await this.updateInfoOnGuide(UUID, "First Name", firstName);
      await this.updateInfoOnGuide(UUID, "Last Name", lastName);
      await this.updateInfoOnGuide(UUID, "Phone Number", countryCode + phone);
      await this.updateInfoOnGuide(UUID, "Email", email);

      const { status, message } = res.data;
      this.showNotification(message, status);
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  showDeleteConfirm = (assessmentId) => {
    confirm({
      title: "Delete Task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content:
        "Are you certain you want to delete this task? This action is irreversible.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        return new Promise<void>((resolve, reject) => {
          this.handleDeleteAssessment(assessmentId)
            .then((status) => {
              if (status) {
                this.showNotification("Successfully deleted", status);
                resolve();
              } else {
                reject(new Error("Failed to delete the assessment"));
              }
            })
            .catch((error) => {
              console.error("Error deleting assessment:", error);
              reject(error);
            });
        }).catch(() => {
          console.log("Unknown errors!");
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  render = () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showModal,
      message,
      notificationStatus,
      UUID,
      firstName,
      lastName,
      countryCode,
      phone,
      email,
      createdAt,
      lastLoginAt,
      // resetLink,
      resultList,
      paymentList,
      userAssessmentList,
      uploadProgress,
      AWSFolderName,
    } = this.state;

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.id")}</ContentSubtitle>
          <Input disabled={true} value={id} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.UUID")}</ContentSubtitle>
          <Input
            disabled={true}
            onChange={this.handleInputChange}
            id="UUID"
            type="text"
            value={UUID}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.firstName")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="firstName"
            type="text"
            value={firstName}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.lastName")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="lastName"
            type="text"
            value={lastName}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.countryCode")}</ContentSubtitle>
          <DropDown
            componentName="admin - user"
            type="Country code"
            defaultSelection={countryCode}
            selectedText={countryCode}
            onOptionChange={this.handleCountryCodeChange}
            options={getCountryCodeList(t)}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.phone")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="phone"
            type="text"
            value={phone}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.email")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="email"
            type="text"
            value={email}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.createdAt")}</ContentSubtitle>
          <Input disabled={true} value={createdAt} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.lastLoginAt")}</ContentSubtitle>
          <Input disabled={true} value={lastLoginAt} />
        </ContentContainer>

        {/* <ContentContainer>
          <ResetPasswordButton onClick={this.handleResetPassword}>
            {t("admin.user.resetPassword")}
          </ResetPasswordButton>
        </ContentContainer>

        {resetLink !== "" && (
          <ContentContainer>
            <RestLink>{resetLink}</RestLink>
            <CancelButton
              onClick={() => {
                navigator.clipboard.writeText(resetLink);
              }}
            >
              Copy
            </CancelButton>
          </ContentContainer>
        )} */}
        <Title>Results</Title>
        <Table>
          <ResultHeader>
            <Item>{t("admin.result.transactionId")}</Item>
            <Item>{t("admin.result.productName")}</Item>
            <Item>{t("admin.result.currentStep")}</Item>
            <Item>{t("admin.result.completed")}</Item>
            <Item>{t("admin.result.startAt")}</Item>
            <Item>{t("admin.result.finishAt")}</Item>
          </ResultHeader>
          {resultList.map((result: ResultType) => (
            <ResultRow
              onClick={() =>
                this.props.history.push("/admin/result/edit/" + result.id)
              }
            >
              <Item>{result.transactionId}</Item>
              <Item>{result.product}</Item>
              <Item>{result.currentStep}</Item>
              <Item>{result.completed ? "yes" : "no"}</Item>
              <TimeItem>
                {GET_DATE_TIME_STRING_FROM_TIMESTAMP(result.startAt)}
              </TimeItem>
              <TimeItem>
                {GET_DATE_TIME_STRING_FROM_TIMESTAMP(result.finishAt)}
              </TimeItem>
            </ResultRow>
          ))}
        </Table>

        <Title>Payments</Title>
        <Table>
          <PaymentHeader>
            <Item>{t("admin.payment.transactionId")}</Item>
            <Item>{t("admin.payment.product")}</Item>
            <Item>{t("admin.payment.currency")}</Item>
            <Item>{t("admin.payment.paymentMethod.title")}</Item>
            <Item>{t("admin.payment.price")}</Item>
            <Item>{t("admin.payment.status.title")}</Item>
            <Item>{t("admin.payment.createdAt")}</Item>
          </PaymentHeader>
          {paymentList.map((payment: PaymentType) => (
            <PaymentRow
              onClick={() =>
                this.props.history.push("/admin/payment/edit/" + payment.id)
              }
            >
              <Item>{payment.transactionId}</Item>
              <Item>{payment.name}</Item>
              <Item>{payment.currency}</Item>
              <Item>
                {t(`admin.payment.paymentMethod.${payment.paymentMethod}`)}
              </Item>
              <Item>{payment.price}</Item>
              <Item>{t(`admin.payment.status.${payment.status}`)}</Item>
              <TimeItem>
                {GET_DATE_TIME_STRING_FROM_TIMESTAMP(payment.createdAt)}
              </TimeItem>
            </PaymentRow>
          ))}
        </Table>
        <Title>Upload Assessment</Title>
        <UploadContainer>
          <EvaluationLabel htmlFor="evaluationName">
            Evaluation Name:<Asterisk>*</Asterisk>
          </EvaluationLabel>
          <EvaluationInput
            type="text"
            placeholder="Enter evaluation name"
            value={this.state.evaluationName}
            onChange={(e) => this.setState({ evaluationName: e.target.value })}
            required
          />
          <EvaluationLabel htmlFor="evaluationAgency">
            Evaluation Agency:<Asterisk>*</Asterisk>
          </EvaluationLabel>
          <EvaluationInput
            type="text"
            placeholder="Enter evaluation agency"
            value={this.state.evaluationAgency}
            onChange={(e) =>
              this.setState({ evaluationAgency: e.target.value })
            }
            required
          />
          <EvaluationLabel htmlFor="evaluationDate">
            Evaluation Date:<Asterisk>*</Asterisk>
          </EvaluationLabel>
          <EvaluationInput
            type="date"
            value={this.state.evaluationDate}
            onChange={(e) => this.setState({ evaluationDate: e.target.value })}
            required
          />
          <EvaluationLabel htmlFor="Upload_1">
            Upload File:<Asterisk>*</Asterisk>
          </EvaluationLabel>
          <UploadInput
            type="file"
            id="Upload_1"
            accept=".pdf"
            onChange={this.handleFileChange}
            required
          />
          {this.state.file && this.state.file.name ? (
            <p>{this.state.file.name}</p> // Display file name if file is uploaded
          ) : (
            <UploadButton
              htmlFor="Upload_1"
              src={uploadProgress === 100 ? fileSuccess : addFile}
            >
              <UploadButtonText>
                {t("user.survey.corePro.video.videoRecording.uploadText")}
              </UploadButtonText>
            </UploadButton>
          )}
          <br />
          <SubmitButton onClick={this.handleSubmit}>Submit</SubmitButton>
        </UploadContainer>

        {userAssessmentList && userAssessmentList.length > 0 ? (
          <Table>
            <AssessmentHeader>
              <Item>{t("admin.user.assessment.id")}</Item>
              <Item>{t("admin.user.assessment.name")}</Item>
              <Item>{t("admin.user.assessment.evaluationName")}</Item>
              <Item>{t("admin.user.assessment.evaluationAgency")}</Item>
              <Item>{t("admin.user.assessment.evaluationDate")}</Item>
              <Item>Action</Item>
            </AssessmentHeader>
            {userAssessmentList.map((assessment: any) => (
              <AssessmentRow
                onClick={() =>
                  handleOpenPDFFromAWS({
                    folderName: AWSFolderName,
                    fileName: assessment.name,
                  })
                }
              >
                <Item>{assessment.id}</Item>
                <Item>{assessment.name}</Item>
                <Item>{assessment.evaluationName}</Item>
                <Item>{assessment.evaluationAgency}</Item>
                <Item>{assessment.evaluationDate.substring(0, 10)}</Item>
                <Item>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      this.showDeleteConfirm(assessment.id);
                    }}
                    type="dashed"
                  >
                    Delete
                  </Button>
                </Item>
              </AssessmentRow>
            ))}
          </Table>
        ) : null}
      </Container>
    );
  };
}

export default withTranslation()(UserEdit);
