import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../common/NotificationModal";
import Pagination from "../../common/Pagination";
import SearchInput from "../../common/SearchInput";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { getPageStartAndEnd, debounce } from "../../../util/admin";
import {
  Container,
  Table,
  Header,
  Row,
  ToolContainer,
  Page,
  Button,
  LinkButton,
  TD,
} from "../styles";
import { JS_COOKIE } from "util/auth";

interface paymentType {
  id: string;
  transactionId: string;
  status: string;
  productName: string;
  userId: string;
  createdAt: string;
  paymentMethod: string;
}

interface PaymentProps {
  history: History<LocationState>;
}

interface PaymentStates {
  payment: Array<paymentType>;
  rowCount: number;
  pageCount: number;
  offset: number;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  selectedItem: number;
  queryString: string;
}

type Props = PaymentProps & WithTranslation;
class Payment extends Component<Props, PaymentStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      payment: [],
      rowCount: 0,
      pageCount: 0,
      offset: 0,
      message: "",
      showModal: false,
      notificationStatus: false,
      selectedItem: -1,
      queryString: "",
    };
  }

  componentDidMount = () => {
    this.getPaymentData(0);
  };

  componentDidUpdate = (
    _: PaymentProps,
    { offset: prevOffset, queryString: preQueryString }: PaymentStates
  ) => {
    const { offset, queryString } = this.state;
    if (prevOffset !== offset || preQueryString !== queryString) {
      this.getPaymentData(1000);
    }
  };

  getPaymentData = (time: number) =>
    debounce(async () => {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { offset, queryString } = this.state;
      const { data } = await axios.get(api_address + "api/admin/payment", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          filter: queryString,
          offset: offset * LIMIT,
          limit: LIMIT,
        },
      });
      const { payment, rowCount } = data;
      const pageCount = Math.ceil(rowCount / LIMIT);
      this.setState({
        payment: payment,
        rowCount: rowCount,
        pageCount: pageCount,
      });
    }, time)();

  handlePageChange = (offset: number) => {
    this.setState({ offset: offset });
  };

  handleQueryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      queryString: event.target.value,
    });
  };

  onRowClick = (index: number) => {
    const { selectedItem } = this.state;
    if (selectedItem === index) {
      this.setState({ selectedItem: -1 });
    } else {
      this.setState({ selectedItem: index });
    }
  };

  onEditClick = () => {
    const { selectedItem, payment } = this.state;
    const ID = payment[selectedItem].id;
    this.props.history.push("/admin/payment/edit/" + ID);
  };

  tableData = () => {
    const { payment, selectedItem } = this.state;
    const { t } = this.props;
    return payment.map((payment: paymentType, index: number) => {
      const {
        id,
        transactionId,
        status,
        productName,
        userId,
        createdAt,
        paymentMethod,
      } = payment;
      const isSelected = selectedItem === index;
      return (
        <Row
          key={index}
          isSelected={isSelected}
          onClick={() => {
            this.onRowClick(index);
          }}
        >
          <td>{id}</td>
          <td>{transactionId}</td>
          <td>{paymentMethod}</td>
          <td>{status}</td>
          <td>{productName}</td>
          <td>
            <LinkButton
              isDisabled={false}
              onClick={() => {
                this.props.history.push("/admin/user/edit/" + userId);
              }}
            >
              {userId}
            </LinkButton>
          </td>
          <TD>{GET_DATE_TIME_STRING_FROM_TIMESTAMP(createdAt)}</TD>
        </Row>
      );
    });
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      rowCount,
      pageCount,
      offset,
      selectedItem,
      payment,
      queryString,
    } = this.state;

    const { pageStart, pageEnd } = getPageStartAndEnd(offset, payment.length);

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <ToolContainer>
          <SearchInput
            queryString={queryString}
            placeholder="transactionId"
            handleQueryInput={this.handleQueryInput}
          />
          {selectedItem !== -1 && (
            <Button onClick={this.onEditClick}>Edit</Button>
          )}
        </ToolContainer>
        <Table id="payment">
          <tbody>
            <Header>
              <th>{t("admin.payment.id")}</th>
              <th>{t("admin.payment.transactionId")}</th>
              <th>{t("admin.payment.paymentMethod.title")}</th>
              <th>{t("admin.payment.status.title")}</th>
              <th>{t("admin.payment.product")}</th>
              <th>{t("admin.payment.userId")}</th>
              <th>{t("admin.payment.createdAt")}</th>
            </Header>
            {this.tableData()}
          </tbody>
        </Table>
        <ToolContainer>
          <Pagination
            selectedItem={offset}
            pageCount={pageCount}
            handlePageChange={this.handlePageChange}
          />
          <Page>{`${pageStart} - ${pageEnd} of ${rowCount}`}</Page>
        </ToolContainer>
      </Container>
    );
  };
}

export default withTranslation()(Payment);
