import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../common/NotificationModal";
import ReferralLinkModal from "../../common/ReferralLinkModal";
import Pagination from "../../common/Pagination";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { getPageStartAndEnd } from "../../../util/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import {
  Container,
  Table,
  Header,
  Row,
  ToolContainer,
  Page,
  Button,
  TD,
} from "../styles";
import { JS_COOKIE } from "util/auth";

interface couponType {
  id: string;
  couponCode: string;
  redeemBy: string;
  currentRedemption: string;
  maxRedemption: string;
  applyTo: Array<string>;
  applyAll: boolean;
}

interface CouponProps {
  history: History<LocationState>;
}

interface CouponStates {
  coupon: Array<couponType>;
  rowCount: number;
  pageCount: number;
  offset: number;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  selectedItem: number;
  showReferralLinkModal: boolean;
}

type Props = CouponProps & WithTranslation;
class Coupon extends Component<Props, CouponStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      coupon: [],
      rowCount: 0,
      pageCount: 0,
      offset: 0,
      message: "",
      showModal: false,
      notificationStatus: false,
      selectedItem: -1,
      showReferralLinkModal: false,
    };
  }

  componentDidMount = () => {
    this.getCouponData();
  };

  componentDidUpdate = (prevProps: CouponProps, prevState: CouponStates) => {
    if (prevState.offset === this.state.offset) {
      return;
    }
    this.getCouponData();
  };

  getCouponData = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { offset } = this.state;
      const res = await axios.get(api_address + "api/admin/coupon", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: offset * LIMIT,
          limit: LIMIT,
        },
      });
      const { coupon, rowCount } = res.data;
      const pageCount = Math.ceil(rowCount / LIMIT);
      this.setState({
        coupon,
        rowCount,
        pageCount,
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  handlePageChange = (offset: number) => {
    this.setState({ offset: offset });
  };

  handleOpenReferralLinkModal = () => {
    this.setState({ showReferralLinkModal: true });
  };

  handleCloseReferralLinkModal = () => {
    this.setState({ showReferralLinkModal: false });
  };

  onRowClick = (index: number) => {
    const { selectedItem } = this.state;
    if (selectedItem === index) {
      this.setState({ selectedItem: -1 });
    } else {
      this.setState({ selectedItem: index });
    }
  };

  onEditClick = () => {
    const { selectedItem, coupon } = this.state;
    const ID = coupon[selectedItem].id;
    this.props.history.push("/admin/coupon/edit/" + ID);
  };

  onCreateClick = () => {
    this.props.history.push("/admin/coupon/create/0");
  };

  tableData = () => {
    const { coupon, selectedItem } = this.state;
    const { t } = this.props;
    return coupon.map((coupon: couponType, index: number) => {
      const {
        id,
        couponCode,
        redeemBy,
        currentRedemption,
        maxRedemption,
        applyTo,
      } = coupon;
      const isSelected = selectedItem === index;
      return (
        <Row
          key={index}
          isSelected={isSelected}
          onClick={() => {
            this.onRowClick(index);
          }}
        >
          <td>{id}</td>
          <td>{couponCode}</td>
          <td>{`${currentRedemption} / ${maxRedemption}`}</td>
          <td>{applyTo.join(", ")}</td>
          <TD>{GET_DATE_TIME_STRING_FROM_TIMESTAMP(redeemBy)}</TD>
        </Row>
      );
    });
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      rowCount,
      pageCount,
      offset,
      selectedItem,
      coupon,
    } = this.state;

    const { pageStart, pageEnd } = getPageStartAndEnd(offset, coupon.length);

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <ReferralLinkModal {...this.state} {...this} />
        <ToolContainer>
          <Button onClick={this.handleOpenReferralLinkModal}>
            {t("admin.coupon.link")}
          </Button>
          <Button onClick={this.onCreateClick}>Create</Button>
          {selectedItem !== -1 && (
            <Button onClick={this.onEditClick}>Edit</Button>
          )}
        </ToolContainer>
        <Table id="coupon">
          <tbody>
            <Header>
              <th>{t("admin.coupon.id")}</th>
              <th>{t("admin.coupon.couponCode")}</th>
              <th>{t("admin.coupon.redemption")}</th>
              <th>{t("admin.coupon.applyTo")}</th>
              <th>{t("admin.coupon.redeemBy")}</th>
            </Header>
            {this.tableData()}
          </tbody>
        </Table>
        <ToolContainer>
          <Pagination
            selectedItem={offset}
            pageCount={pageCount}
            handlePageChange={this.handlePageChange}
          />
          <Page>{`${pageStart} - ${pageEnd} of ${rowCount}`}</Page>
        </ToolContainer>
      </Container>
    );
  };
}

export default withTranslation()(Coupon);
