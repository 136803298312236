import {
  ChevronIcon,
  PaginationContainer,
  ActionButton,
  DotButton,
} from "./styles";

type Props = {
  pageCount: number;
  selectedItem: number;
  handlePageChange: (offset: number) => void;
};

const ActionItem = ({ offset, index, handlePageChange }) => (
  <ActionButton
    active={offset === index}
    onClick={() => handlePageChange(index)}
  >
    {index + 1}
  </ActionButton>
);

const DotItem = () => (
  <DotButton active={false} onClick={() => {}}>
    ⋯
  </DotButton>
);

const Pagination = ({ pageCount, selectedItem, handlePageChange }: Props) => {
  const handlePrevious = () => {
    if (selectedItem - 1 >= 0) {
      handlePageChange(selectedItem - 1);
    }
  };
  const handleNext = () => {
    if (selectedItem < pageCount - 1) {
      handlePageChange(selectedItem + 1);
    }
  };

  if (pageCount <= 6) {
    return (
      <PaginationContainer>
        <ActionButton active={false} onClick={handlePrevious}>
          <ChevronIcon className="chevron left" size="small" />
        </ActionButton>
        {Array.from({ length: pageCount }, (_, x) => x).map((item: number) => (
          <ActionItem
            offset={selectedItem}
            index={item}
            handlePageChange={handlePageChange}
          />
        ))}
        <ActionButton active={false} onClick={handleNext}>
          <ChevronIcon className="chevron right" size="small" />
        </ActionButton>
      </PaginationContainer>
    );
  }

  let centerIndex = selectedItem;
  if (selectedItem < 3) {
    centerIndex = 3;
  } else if (selectedItem > pageCount - 4) {
    centerIndex = pageCount - 4;
  }
  return (
    <PaginationContainer>
      <ActionButton active={false} onClick={handlePrevious}>
        <ChevronIcon className="chevron left" size="small" />
      </ActionButton>
      <ActionItem
        offset={selectedItem}
        index={0}
        handlePageChange={handlePageChange}
      />
      {selectedItem < 3 + 1 ? (
        <ActionItem
          offset={selectedItem}
          index={1}
          handlePageChange={handlePageChange}
        />
      ) : (
        <DotItem />
      )}
      {[centerIndex - 1, centerIndex, centerIndex + 1].map((index: number) => (
        <ActionItem
          offset={selectedItem}
          index={index}
          handlePageChange={handlePageChange}
        />
      ))}
      {selectedItem < pageCount - 3 ? (
        <DotItem />
      ) : (
        <ActionItem
          offset={selectedItem}
          index={pageCount - 2}
          handlePageChange={handlePageChange}
        />
      )}
      <ActionItem
        offset={selectedItem}
        index={pageCount - 1}
        handlePageChange={handlePageChange}
      />
      <ActionButton active={false} onClick={handleNext}>
        <ChevronIcon className="chevron right" size="small" />
      </ActionButton>
    </PaginationContainer>
  );
};

export default Pagination;
