import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  CancelButton,
  DeleteButton,
  SaveButton,
} from "../../styles";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { JS_COOKIE } from "util/auth";

interface Match {
  id: string;
}

interface OrganizationEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface OrganizationEditStates {
  [key: string]: string | boolean;
  name: string;
  organizationCode: string;
  defaultProductId: string;
  createdAt: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = OrganizationEditProps & WithTranslation;
class OrganizationEdit extends Component<Props, OrganizationEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      organizationCode: "",
      defaultProductId: "",
      createdAt: "",
      message: "",
      showModal: false,
      notificationStatus: false,
    };
  }

  componentDidMount = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;
    axios
      .get(api_address + "api/admin/organization/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { name, organizationCode, defaultProductId, createdAt } =
          res.data.organization;
        this.setState({
          name,
          organizationCode,
          defaultProductId,
          createdAt,
        });
      })
      .catch((error) => {
        this.showNotification(error.message, false);
      });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  validation = () => {
    // defaultProductId
    const { name, organizationCode } = this.state;
    if (name === "") {
      throw new Error("name is missing");
    }
    if (organizationCode === "") {
      throw new Error("organization code is missing");
    }
  };

  onCancelClick = () => {
    this.props.history.push("/admin/organization");
  };

  onDeleteClick = () => {
    this.showNotification(
      "Deleting organizations is not yet implemented",
      false
    );
  };

  onSaveClick = async () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { name, organizationCode, defaultProductId } = this.state;
      const res = await axios.put(
        api_address + "api/admin/organization/" + id,
        { name, organizationCode, defaultProductId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      this.showNotification(message, status);
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  render() {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showModal,
      message,
      notificationStatus,
      name,
      organizationCode,
      defaultProductId,
      createdAt,
    } = this.state;

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <ContentContainer>
          <ContentSubtitle>{t("admin.organization.id")}</ContentSubtitle>
          <Input disabled={true} value={id} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.organization.name")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="name"
            type="text"
            value={name}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.organization.code")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="organizationCode"
            type="text"
            value={organizationCode}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>
            {t("admin.organization.defaultProductId")}
          </ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="defaultProductId"
            type="number"
            value={defaultProductId}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.organization.createdAt")}</ContentSubtitle>
          <Input disabled={true} value={createdAt} />
        </ContentContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>

          <DeleteButton onClick={this.onDeleteClick}>
            {t("admin.common.delete")}
          </DeleteButton>
        </ContentContainer>
      </Container>
    );
  }
}

export default withTranslation()(OrganizationEdit);
