export const WECHAT_ASSISTANT = "qierbao2help";

export const FACEBOOK_LINK_TW = "https://www.facebook.com/qierbao/";
export const FACEBOOK_LINK_US = "https://www.facebook.com/PenguinSmartUS/";

export const MESSENGER_LINK_TW = "https://www.messenger.com/t/qierbao";
export const MESSENGER_LINK_US = "https://www.messenger.com/t/PenguinSmartUS";

export const EMAIL_LINK = "mailto:support@mypenguinsmart.com";

export const THERAPIST_PROFILE_PICTURE_FOLDER = "profile-picture/therapists";
export const THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE =
  process.env.REACT_APP_AWS_GUIDE_S3_ROUTE +
  "/" +
  THERAPIST_PROFILE_PICTURE_FOLDER +
  "/";
export const ROLE = {
  therapist: "Therapist",
  user: "Client",
  admin: "Admin",
  tmpUser: "TmpUser",
  translator: "translator",
};

export const GRAPHQL_RETURN_STATUS = {
  success: "SUCCESS",
  error: "ERROR",
};

export const EVAL_STEPS = {
  basicIntro: "basic_intro",
  kksIntro: "kks_intro",
  childInfo: "child_info",
  soundCollection: "sound_collection",
  questionnaire: "questionnaire",
  expression: "expression",
  comprehension: "comprehension",
  oral: "oral",
  social: "social",
  preliteracy: "preliteracy",
  video: "video",
  report: "report",
  partialReport: "partial_report",
  expertAnalysis: "expertAnalysis",
  consultation: "consultation",
  localEvaluation: "localEvaluation",
};

export const QUESTIONNAIRE_STEPS = [
  EVAL_STEPS.kksIntro,
  EVAL_STEPS.basicIntro,
  EVAL_STEPS.questionnaire,
  EVAL_STEPS.childInfo,
  EVAL_STEPS.soundCollection,
];

export const VIDEO_STATUS = {
  waiting: "waiting",
  reviewing: "reviewing",
  pending: "pending",
  rejected: "rejected",
  finish: "finish",
};

export const COUPON_DISCOUNT_TYPE = {
  amount: "amount",
  percent: "percent",
  coupon: "coupon",
};

export const DEVELOPMENT_STATUS = {
  advanced: "advanced",
  normal: "normal",
  borderline: "borderline",
  delayed: "delayed",
};

export const PRODUCTS = {
  ExpertEval: "ExpertEval",
  Expression: "Expression",
  ModCom: "ModCom",
  ModExp: "ModExp",
  ModBuyUp: "ModBuyUp",
  ModularizedEval: "ModularizedEval",
  BJCH_Questionnaire: "BJCH_Questionnaire",
  Core: "Core",
  CoreProBuyUp: "CoreProBuyUp",
  CorePro: "CorePro",
  CPCWithReport: "CustomizedPrivateCoachingWithReport",
  CPCBuyUp: "CustomizedPrivateCoachingBuyUp",
  CPC_Post: "CustomizedPrivateCoachingPostTest",
  PEAWithReport: "ParentEducationAcademyWithReport",
  PEA_Post: "ParentEducationAcademyPostTest",
  CPC: "CustomizedPrivateCoaching",
  PEA: "ParentEducationAcademy",
  CPCTrial: "CustomizedPrivateCoachingTrial",
  CPCFlexWithReport: "CustomizedPrivateCoachingFlexWithReport",
  CPCFlex: "CustomizedPrivateCoachingFlex",
  SpecializedAssessment: "SpecializedAssessment",
  CPCBH: "CustomizedPrivateCoachingBH",
  QuickScreener: "QuickScreener",
};

export const MODULARIZED_PRODUCTS = [
  PRODUCTS.Expression,
  PRODUCTS.ModExp,
  PRODUCTS.ModCom,
];

export const SELF_PRODUCTS = [
  PRODUCTS.Core,
  PRODUCTS.ModBuyUp,
  PRODUCTS.BJCH_Questionnaire,
];

export const EXPERT_PRODUCTS = [
  PRODUCTS.ExpertEval,
  PRODUCTS.CorePro,
  PRODUCTS.CPCWithReport,
  PRODUCTS.CPC_Post,
  PRODUCTS.PEAWithReport,
  PRODUCTS.PEA_Post,
  PRODUCTS.CoreProBuyUp,
  PRODUCTS.CPCBuyUp,
  PRODUCTS.CPCFlexWithReport,
];

export const EXPERT_PRODUCTS_WITHOUT_REPORT = [
  PRODUCTS.CPC_Post,
  PRODUCTS.PEA_Post,
  PRODUCTS.CPC,
  PRODUCTS.PEA,
  PRODUCTS.CPCTrial,
  PRODUCTS.CPCBH,
  PRODUCTS.CPCFlex,
  PRODUCTS.SpecializedAssessment,
];

export const EXPERT_PRODUCTS_FOR_ADMIN_PANEL = [
  PRODUCTS.ExpertEval,
  PRODUCTS.CorePro,
  PRODUCTS.CPCWithReport,
  PRODUCTS.CPC_Post,
  PRODUCTS.PEAWithReport,
  PRODUCTS.PEA_Post,
  PRODUCTS.CoreProBuyUp,
  PRODUCTS.CPC,
  PRODUCTS.PEA,
  PRODUCTS.CPCTrial,
  PRODUCTS.CPCBH,
  PRODUCTS.CPCBuyUp,
  PRODUCTS.CPCFlex,
  PRODUCTS.CPCFlexWithReport,
  PRODUCTS.SpecializedAssessment,
];

export const CURRENCY = {
  cny: "cny",
  twd: "twd",
  usd: "usd",
};

export const CURRENCY_LABEL = {
  cny: "¥",
  twd: "$",
  usd: "$",
};

export const ORGANIZATION = {
  BJCH: "BJCH",
};

export const GUIDE_PRODUCTS = [
  PRODUCTS.CPC,
  PRODUCTS.PEA,
  PRODUCTS.CPCWithReport,
  PRODUCTS.PEAWithReport,
  PRODUCTS.CPCTrial,
  PRODUCTS.CPCBH,
  PRODUCTS.CPCBuyUp,
  PRODUCTS.CPCFlexWithReport,
  PRODUCTS.CPCFlex,
];

export const PRODUCTS_THAT_CAN_BUY_UP_TO_CPC = [PRODUCTS.CorePro];

export const PRODUCTS_WITH_REPORT = [
  PRODUCTS.CPCWithReport,
  PRODUCTS.PEAWithReport,
  PRODUCTS.CorePro,
  PRODUCTS.CoreProBuyUp,
  PRODUCTS.CPCBuyUp,
  PRODUCTS.CPCFlexWithReport,
];

export const PRODUCTS_WITH_VIDEO_EVAL = [
  PRODUCTS.ExpertEval,
  PRODUCTS.CorePro,
  PRODUCTS.CPCWithReport,
  PRODUCTS.CPC_Post,
  PRODUCTS.PEAWithReport,
  PRODUCTS.PEA_Post,
  PRODUCTS.CoreProBuyUp,
  PRODUCTS.CPC,
  PRODUCTS.PEA,
  PRODUCTS.CPCTrial,
  PRODUCTS.CPCBH,
  PRODUCTS.CPCBuyUp,
  PRODUCTS.CPCFlexWithReport,
];

export const PRODUCTS_WITH_CHILD_INFO_ONLY = [
  PRODUCTS.CPCFlex,
  PRODUCTS.SpecializedAssessment,
];
