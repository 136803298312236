import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../common/NotificationModal";
import Pagination from "../../common/Pagination";
import SearchInput from "../../common/SearchInput";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { getPageStartAndEnd, debounce } from "../../../util/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import {
  Container,
  Table,
  Header,
  Row,
  Button,
  ToolContainer,
  Page,
  TD,
} from "../styles";
import { JS_COOKIE } from "util/auth";

interface userType {
  id: string;
  UUID: string;
  firstName: string;
  lastName: string;
  currentstep: string;
  productName: string;
  createdat: string;
  lastloginat: string;
}

interface UserProps {
  history: History<LocationState>;
}

interface UserStates {
  user: Array<userType>;
  rowCount: number;
  pageCount: number;
  offset: number;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  selectedItem: number;
  queryString: string;
}

type Props = UserProps & WithTranslation;
class User extends Component<Props, UserStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: [],
      rowCount: 0,
      pageCount: 0,
      offset: 0,
      message: "",
      showModal: false,
      notificationStatus: false,
      selectedItem: -1,
      queryString: "",
    };
  }

  componentDidMount = () => {
    this.getUserData(0);
  };

  componentDidUpdate = (
    _: UserProps,
    { offset: prevOffset, queryString: preQueryString }: UserStates
  ) => {
    const { offset, queryString } = this.state;
    if (prevOffset !== offset || preQueryString !== queryString) {
      this.getUserData(1000);
    }
  };

  getUserData = (time: number) =>
    debounce(async () => {
      try {
        const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
        const { offset, queryString } = this.state;
        const { data } = await axios.get(api_address + "api/admin/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter: queryString,
            offset: offset * LIMIT,
            limit: LIMIT,
          },
        });
        const { user, rowCount } = data;
        const pageCount = Math.ceil(rowCount / LIMIT);

        this.setState({
          user: user,
          rowCount: rowCount,
          pageCount: pageCount,
        });
      } catch (err) {
        console.error(err);
      }
    }, time)();

  handlePageChange = (offset: number) => {
    this.setState({ offset: offset });
  };

  onRowClick = (index: number) => {
    const { selectedItem } = this.state;
    if (selectedItem === index) {
      this.setState({ selectedItem: -1 });
    } else {
      this.setState({ selectedItem: index });
    }
  };

  onEditClick = () => {
    const { selectedItem, user } = this.state;
    const ID = user[selectedItem].id;
    this.props.history.push("/admin/user/edit/" + ID);
  };

  onCreateClick = () => {
    this.props.history.push("/admin/user/create");
  };

  handleQueryInput = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      queryString: value,
    });
  };

  tableData = () => {
    const { t } = this.props;
    const { user, selectedItem } = this.state;

    return user?.map((user: userType, index: number) => {
      const isSelected = selectedItem === index;
      return (
        <Row
          key={index}
          isSelected={isSelected}
          onClick={() => this.onRowClick(index)}
        >
          <td>{user.id}</td>
          <td>{user.UUID}</td>
          <td>{user.lastName}</td>
          <td>{user.firstName}</td>
          <td>{user.currentstep && t(`admin.user.${user.currentstep}`)}</td>
          <td>{user.productName}</td>
          <TD>{GET_DATE_TIME_STRING_FROM_TIMESTAMP(user.lastloginat)}</TD>
          <TD>{GET_DATE_TIME_STRING_FROM_TIMESTAMP(user.createdat)}</TD>
        </Row>
      );
    });
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      rowCount,
      pageCount,
      offset,
      selectedItem,
      user,
      queryString,
    } = this.state;

    const { pageStart, pageEnd } = getPageStartAndEnd(offset, user?.length);

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <ToolContainer>
          <SearchInput
            queryString={queryString}
            placeholder="email"
            handleQueryInput={this.handleQueryInput}
          />
          <Button onClick={this.onCreateClick}>Create</Button>
          {selectedItem !== -1 && (
            <Button onClick={this.onEditClick}>Edit</Button>
          )}
        </ToolContainer>
        <Table id="user">
          <tbody>
            <Header>
              <th>{t("admin.user.id")}</th>
              <th>{t("admin.user.UUID")}</th>
              <th>{t("admin.user.lastName")}</th>
              <th>{t("admin.user.firstName")}</th>
              <th>{t("admin.user.currentStep")}</th>
              <th>{t("admin.user.product")}</th>
              <th>{t("admin.user.lastLoginAt")}</th>
              <th>{t("admin.user.createdAt")}</th>
            </Header>
            {this.tableData()}
          </tbody>
        </Table>
        <ToolContainer>
          <Pagination
            selectedItem={offset}
            pageCount={pageCount}
            handlePageChange={this.handlePageChange}
          />
          <Page>{`${pageStart} - ${pageEnd} of ${rowCount}`}</Page>
        </ToolContainer>
      </Container>
    );
  };
}

export default withTranslation()(User);
