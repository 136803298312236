import styled from "styled-components";

// Wording
export const PageTitle = styled.div`
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: normal;
  text-align: center;
`;

export const PageSubtitle = styled.div`
  font-size: 16px;
  line-height: normal;
`;

export const SectionTitle = styled.div`
  color: #31849b;
  font-size: 30px;
  font-weight: 300;
  margin: 0 0 20px 0;
  line-height: normal;
`;

export const SectionTitleReviewAndSuggestions = styled(SectionTitle)`
  margin: 0 0 10px 0;
`;

export const SectionTitleBasicInfo = styled(SectionTitle)`
  margin: 0 0 10px 0;
`;

export const SmallSectionTitle = styled.div`
  margin: 20px 0 10px 0;
  font-size: 16px;
  font-weight: 400;
`;

export const SmallSectionTitleReviewAndSuggestions = styled(SmallSectionTitle)`
  margin: 10px 0 5px 0;
`;

export const SmallSectionTitleActivities = styled(SmallSectionTitle)`
  margin: 0 0 5px 0;
`;

export const ActivityTitle = styled.div`
  margin: 20px 0 10px 0;
  font-size: 20px;
  text-align: center;
`;

export const ContentText = styled.div`
  font-size: 14px;
  margin: 0 0 10px 0;
  white-space: break-spaces;
`;

export const ContentTextReviewAndSuggestions = styled(ContentText)`
  line-height: 19px;
`;

export const ContentTextActivities = styled(ContentText)`
  font-size: 12px;
  margin: 0 0 5px 0;
`;

export const ListText = styled.li`
  font-size: 14px;
  margin-top: 10px;
`;

export const ListTextReviewAndSuggestions = styled(ListText)`
  line-height: 16px;
`;

export const Bold = styled.div`
  font-weight: bold;
`;

// container
export const Container = styled.div`
  padding-bottom: 50px;
`;

export const OtherActionContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 40px;
  left: 70px;
  width: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export const A4Container = styled.div`
  position: relative;
  height: 29.687cm;
  width: 21cm;
  padding: 18mm 16mm 18mm 16mm;
  background: white;
  display: block;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  font-size: 12px;
`;

export const SectionContainer = styled.div`
  height: max-content;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 30px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  text-align: left;
  line-height: 22px;
`;

export const SectionContainerReviewAndSuggestions = styled(SectionContainer)`
  margin-bottom: 0px;
  padding: 3px 14px;
`;

export const SectionContainerBasicInfo = styled(SectionContainer)`
  margin-bottom: 0px;
  padding: 5px 20px;
  line-height: 20px;
`;

export const SectionContainerActivities = styled(SectionContainer)`
  margin-bottom: 5px;
  padding: 5px 10px;
  line-height: 16px;
`;

export const ListTitleContainer = styled(ContentText)`
  padding-right: 20px;
`;

export const TitleListContainer = styled(ListTitleContainer)`
  width: 200px;
`;

export const TitleListContainerBasicInfo = styled(TitleListContainer)`
  margin: 0 0 0px 0;
`;

export const ListContentContainer = styled(ContentText)``;

export const TipsContainer = styled(SectionContainer)`
  padding: 10px 10px 1px 10px;
  margin-bottom: 0px;
`;

export const AppendixContainer = styled(SectionContainer)`
  padding: 1px 15px 10px 15px;
  // line-height: 20px;
`;

// Table
export const Table = styled.table`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  margin: 10px 0 40px 0;
`;

export const TH = styled.th`
  padding: 5px 5px 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export const HeadTR = styled.tr`
  color: #31849b;
  background-color: #dbeff3;
`;

export const TR = styled.tr``;

export const FirstTD = styled.td`
  color: #31849b;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 5px 5px 15px;
`;

export const GeneralTD = styled.td`
  color: #31849b;table-cell
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 5px 5px 15px;
  width: 17%;
`;

export const DegreeTD = styled.td`
  color: #31849b;
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
  text-align: center;
`;

export const TD = styled.td`
  color: #31849b;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 5px 5px 15px;
  vertical-align: top;
`;

export const ReviewTD = styled.td`
  color: #31849b;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 5px 5px 15px;
  width: 55%;
`;

export const DescriptionContentTD = styled.div`
  font-weight: normal;
  text-align: left;
`;

// other
export const PageNumber = styled.div`
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
`;
export const WaterMark = styled.img`
  opacity: 0.6;
  position: absolute;
  top: 10px;
  right: 5px;
  height: 50px;
`;
export const Button = styled.div`
  width: 80%;
  padding: 5px 0;
  background: #039be5;
  color: white;
  margin: 5px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  display: grid;
  place-items: center;
  cursor: pointer;
`;
export const TherapistPhoto = styled.img`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;
