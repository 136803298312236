import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const MainColor = "pink";
const BackgroundColor = "red";

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.div<{ active: boolean }>`
  margin: 0 5px 0 0;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  font-style: bold;
  cursor: ${({ active }) => (active ? "default" : "pointer")};
  background-color: ${({ active }) => (active ? MainColor : "#ffffff")};
  color: ${({ active }) => (active ? "white" : "#606060")};
  border-radius: 4px;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${({ active }) => (active ? "#ffffff" : MainColor)};
      background-color: ${({ active }) =>
        active ? MainColor : BackgroundColor};
    }
  }
`;

export const DotButton = styled(ActionButton)`
  cursor: default;
  :hover {
    color: #606060;
    background-color: #ffffff;
  }
`;

export const ChevronIcon = styled(Icon)`
  color: #909090;
  @media (hover: hover) and (pointer: fine) {
    ${ActionButton}:hover & {
      color: ${MainColor};
    }
  }
`;
