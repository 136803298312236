import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../common/NotificationModal";
import Pagination from "../../common/Pagination";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { getPageStartAndEnd } from "../../../util/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import {
  Container,
  Table,
  Header,
  Row,
  Page,
  ToolContainer,
  LinkButton,
  TD,
} from "../styles";
import { JS_COOKIE } from "util/auth";

interface feedbackType {
  id: string;
  userid: string;
  score: string;
  resultId: string;
  product: string;
  maxscore: string;
  comment: string;
  createdAt: string;
}

interface FeedbackProps {
  history: History<LocationState>;
}

interface FeedbackStates {
  feedback: Array<feedbackType>;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  rowCount: number;
  pageCount: number;
  offset: number;
  selectedItem: number;
}

type Props = FeedbackProps & WithTranslation;
class Feedback extends Component<Props, FeedbackStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      feedback: [],
      message: "",
      showModal: false,
      notificationStatus: false,
      rowCount: 0,
      pageCount: 0,
      offset: 0,
      selectedItem: -1,
    };
  }

  componentDidMount = () => {
    this.getFeedbackData();
  };

  componentDidUpdate = (
    prevProps: FeedbackProps,
    prevState: FeedbackStates
  ) => {
    if (prevState.offset === this.state.offset) {
      return;
    }
    this.getFeedbackData();
  };

  getFeedbackData = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { offset } = this.state;
    axios
      .get(api_address + "api/admin/feedback", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: offset * LIMIT,
          limit: LIMIT,
        },
      })
      .then((res) => {
        const { feedback, rowCount } = res.data;
        const pageCount = Math.ceil(rowCount / LIMIT);
        this.setState({
          feedback: feedback,
          rowCount: rowCount,
          pageCount: pageCount,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  handlePageChange = (offset: number) => {
    this.setState({ offset: offset });
  };

  onRowClick = (index: number) => {
    const { selectedItem } = this.state;
    if (selectedItem === index) {
      this.setState({ selectedItem: -1 });
    } else {
      this.setState({ selectedItem: index });
    }
  };

  tableData = () => {
    const { feedback } = this.state;
    const { t } = this.props;
    return feedback.map((feedback: feedbackType, index: number) => {
      const {
        id,
        userid,
        score,
        maxscore,
        comment,
        product,
        resultId,
        createdAt,
      } = feedback;
      return (
        <Row key={index} isSelected={false}>
          <td>{id}</td>
          <td>
            <LinkButton
              isDisabled={false}
              onClick={() => {
                this.props.history.push("/admin/user/edit/" + userid);
              }}
            >
              {userid}
            </LinkButton>
          </td>
          <td>{resultId}</td>
          <td>{t(`admin.product.${product}`)}</td>
          <td>{`${score} / ${maxscore}`}</td>
          <td>{comment}</td>
          <TD>{GET_DATE_TIME_STRING_FROM_TIMESTAMP(createdAt)}</TD>
        </Row>
      );
    });
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      rowCount,
      pageCount,
      offset,
      feedback,
    } = this.state;

    const { pageStart, pageEnd } = getPageStartAndEnd(offset, feedback.length);

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <Table id="feedback">
          <tbody>
            <Header>
              <th>{t("admin.feedback.id")}</th>
              <th>{t("admin.feedback.userId")}</th>
              <th>{t("admin.feedback.resultId")}</th>
              <th>{t("admin.feedback.product")}</th>
              <th>{t("admin.feedback.score")}</th>
              <th>{t("admin.feedback.comment")}</th>
              <th>{t("admin.feedback.createdAt")}</th>
            </Header>
            {this.tableData()}
          </tbody>
        </Table>
        <ToolContainer>
          <Pagination
            selectedItem={offset}
            pageCount={pageCount}
            handlePageChange={this.handlePageChange}
          />
          <Page>{`${pageStart} - ${pageEnd} of ${rowCount}`}</Page>
        </ToolContainer>
      </Container>
    );
  };
}

export default withTranslation()(Feedback);
